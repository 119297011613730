import { useEffect, useState } from 'react'
import {
    ILanguagesOption,
    languageConfig,
    languagesOptions,
} from './languages.config'
import styles from './styles.module.css'
import { Icon } from '@/core/components'
import { useLanguageStore } from '@/store'
import { Languages } from '@/core/typing'
import { storageService } from '@/core/services'
import _ from 'lodash'

interface IProps {
    hideFlag?: boolean
    positionDropdown?: any
    onlyFlag?: boolean
    onClick?: () => void
    hideDropdown?: boolean
}
export const ChooseLanguageWidget = ({
    hideFlag = false,
    positionDropdown,
    onlyFlag,
    onClick = _.noop,
    hideDropdown,
}: IProps) => {
    const { lang, setLang } = useLanguageStore()
    const [selectedLanguage, setSelectedLanguage] = useState<ILanguagesOption>(
        languagesOptions[0],
    )

    useEffect(() => {
        setSelectedLanguage({
            value: languageConfig[lang]?.value,
            label: languageConfig[lang]?.label,
            flagPath: languageConfig[lang]?.flagPath,
        })
    }, [lang])

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (hideDropdown) {
            setIsOpen(false)
        }
    }, [hideDropdown])
    const toggleDropdown = () => setIsOpen(!isOpen)

    const handleLanguageSelect = (language: ILanguagesOption) => {
        setLang(language.value as Languages)
        storageService.set('lang', language.value)
        setSelectedLanguage(language)
        setIsOpen(false)
    }
    const capitalizeFirstLetter = (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }

    const handleClick = () => {
        onClick()
        toggleDropdown()
    }
    return (
        <div className={styles.dropdown_container}>
            <button className={styles.dropdown_button} onClick={handleClick}>
                {hideFlag ? null : (
                    <img
                        className={styles.flag}
                        src={selectedLanguage.flagPath}
                    />
                )}
                {onlyFlag ? null : (
                    <p className={styles.select_label}>
                        {capitalizeFirstLetter(selectedLanguage.value)}
                        <Icon
                            name={isOpen ? 'arrow-up' : 'arrow-down'}
                            size={16}
                            color='#C3C4C8'
                        />
                    </p>
                )}
            </button>
            {isOpen && (
                <div className={styles.dropdown_menu} style={positionDropdown}>
                    {languagesOptions.map(language => (
                        <div
                            key={language.value}
                            className={`${styles.dropdown_item} ${language.value === selectedLanguage.value ? styles.active : ''}`}
                            onClick={() => handleLanguageSelect(language)}>
                            <img src={language.flagPath} />
                            <p>{language.label}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

import { Languages } from '@/core/typing'

export const policyTranslates: any = {
    [Languages.EN]: (
        <>
            <p className='p1'>
                This Privacy Policy (this &ldquo;Policy&rdquo;) applies to the
                trading platform (including any applicable mobile applications
                and websites used to access the same) (collectively the
                &ldquo;Platform&rdquo;) provided by SafeTravy Limited (the
                &ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us&rdquo; or
                &ldquo;our&rdquo;). It describes how the Company collects, uses,
                and discloses Personal Information that we obtain from Users of
                the Platform and any account services provided through the
                Platform, and how we use and disclose that information. For
                purposes of this Policy, &ldquo;Personal Information&rdquo;
                refers to information supplied by a User from which the identity
                of such User may be directly or indirectly determined.
            </p>
            <p className='p1'>
                By registering for and using the Platform, you agree that your
                Personal Information will be handled as described in this Policy
                and the Terms and Conditions applicable to the Platform (the
                &ldquo;Service Agreement&rdquo;); capitalized terms used herein
                shall have the same meaning as set forth in the Service
                Agreement. This Privacy Policy is an integral part of{' '}
                <span className='s1'> SafeTravy Terms and Conditions </span> .
            </p>
            <p className='p1'>
                <strong> What data do we collect? </strong>
            </p>
            <p className='p1'>SafeTravy collects the following data:</p>
            <p className='p1'>
                <strong> Personal identification </strong> information (Name,
                email address, citizenship,etc.)
            </p>
            <p className='p1'>
                <strong> Contact </strong> information (email address)
            </p>
            <p className='p1'>
                <strong> Financial </strong> information (bank account details)
            </p>
            <p className='p1'>
                <strong> Transaction </strong> information (details about
                payments to and from you and other details of products and
                services you have purchased from us)
            </p>
            <p className='p1'>
                <strong> Technical </strong> information (internet protocol (IP)
                address, login data, browser type and version, time zone setting
                and location, operating system and platform and other technology
                on the devices you use to access our websites, products and
                services)
            </p>
            <p className='p1'>
                <strong> Profile </strong> information (username and password,
                subscription purchases made by you, your interests, preferences,
                feedback and survey responses)
            </p>
            <p className='p1'>
                <strong> Usage </strong> information (includes how you use our
                website, products and services)
            </p>
            <p className='p1'>
                <strong> Marketing and Communications </strong> information
                (your preferences in receiving marketing from us and your
                communication preferences)
            </p>
            <p className='p1'>
                <strong> Aggregated </strong> information (statistical or
                demographic data. Aggregated data could be derived from your
                personal data but is not considered personal data in law as this
                data will not directly or indirectly reveal your identity. For
                example, we MAY aggregate your Usage Data to calculate the
                percentage of users accessing a specific website feature.
                However, if we combine or connect Aggregated Data with your
                personal data so that it can directly or indirectly identify
                you, we treat the combined data as personal data which will be
                used in accordance with this privacy policy)
            </p>
            <p className='p1'>
                <strong>
                    {' '}
                    We do not collect any Special Categories of Personal Data{' '}
                </strong>{' '}
                about you (this includes details about your race or ethnicity,
                religious or philosophical beliefs, sex life, sexual
                orientation, political opinions, trade union membership,
                information about your health, and genetic and biometric data).
                Nor do we collect any information about criminal convictions and
                offenses.
            </p>
            <p className='p1'>
                <strong> How do we collect your data? </strong>
            </p>
            <p className='p1'>
                You directly provide SafeTravy with most of the data we collect.
                We collect data and process data when you:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Register online or place an order for any of our
                    subscription plans.
                </li>
                <li className='li1'>
                    Voluntarily complete a customer survey or provide feedback
                    on any of our message boards or via email.
                </li>
                <li className='li1'>
                    Use or view our website via your browser&rsquo;s cookies.
                </li>
                <li className='li1'>
                    Subscribe to a service, publication, newsletter or request
                    marketing be sent to you.
                </li>
                <li className='li1'>
                    Contact us directly via our website, email, telephone or
                    other means of communication.
                </li>
            </ul>
            <p className='p1'>
                SafeTravy will also receive your data indirectly from the
                following sources:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Automated technologies or interactions: As you interact with
                    our website, products and services, we will automatically
                    collect Technical Data about your equipment, browsing
                    actions and patterns. We collect this personal data by using
                    cookies, server logs and other similar technologies.
                </li>
                <li className='li1'>Technical data from:</li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    analytics providers such as Google based outside of the
                    European Union (EU).
                </li>
                <li className='li1'>
                    hosting and other service providers such as Amazon based
                    outside of the EU.
                </li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    Contact, Financial and Transaction Data from providers of
                    technical, payment and delivery services.
                </li>
            </ul>
            <p className='p1'>
                <strong> How We Use Your Information </strong>
            </p>
            <p className='p1'>
                We use your information, including your Personal Information,
                for the following purposes:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    To provide our Platform to you, to facilitate communications
                    and transactions on the Platform, to communicate with you
                    about your use of our Platform, to respond to your
                    inquiries, to fulfill your orders, and for other customer
                    service purposes;
                </li>
                <li className='li1'>
                    To tailor the content and information that we may send or
                    display to you, to offer location customization, and
                    personalized help and instructions, and to otherwise
                    personalize your experiences while using our Platform;
                </li>
                <li className='li1'>
                    To better understand how users access and use the Platform,
                    both on an aggregated and individualized basis, in order to
                    improve our Platform and respond to user desires and
                    preferences, and for other research and analytical purposes;
                </li>
                <li className='li1'>
                    For marketing and promotional purposes. For example, we may
                    use your information, such as your email address or contact
                    number registered with us, to send you news and newsletters,
                    special offers, and promotions, to conduct sweepstakes and
                    contests, or to otherwise contact you about products or
                    information we think may interest you. We also may use the
                    information that we learn about you to assist us in
                    advertising our Platform on third-party websites.
                </li>
            </ul>
            <p className='p1'>
                <strong> How We Share Your Information </strong>
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Affiliates. We may disclose the information we collect from
                    you to our affiliates or subsidiaries solely for the purpose
                    of providing the Platform or related services including
                    subsite services to you; however, if we do so, their use and
                    disclosure of your personally identifiable information will
                    be maintained by such affiliates and subsidiaries in
                    accordance with this Policy.
                </li>
                <li className='li1'>
                    Service Providers. We may disclose the information we
                    collect from you to third-party vendors, service providers,
                    contractors or agents who perform functions on our behalf,
                    provided such third parties have agreed to only use such
                    information to provide services to us.
                </li>
                <li className='li1'>
                    Business Transfers. If we are in negotiations with, or are
                    acquired by or merged with another company or entity, if
                    substantially all of our assets are transferred to another
                    company or entity, or as part of a bankruptcy proceeding, we
                    may transfer the information we have collected from you to
                    the other company or entity.
                </li>
                <li className='li1'>
                    In Response to Legal Process. We also may disclose the
                    information we collect from you in order to comply with the
                    law, a judicial proceeding, court order, or other legal
                    process, such as in response to a subpoena.
                </li>
                <li className='li1'>
                    To Protect Us and Others. We also may disclose the
                    information we collect from you if we believe it is
                    necessary to investigate, prevent, or take action regarding
                    illegal activities, suspected fraud, situations involving
                    potential threats to the safety of any person, violations of
                    our Service Agreement or this Policy, or as evidence in
                    litigation in which we are involved.
                </li>
                <li className='li1'>
                    Aggregate and De-Identified Information. We may collect,
                    process, analyze and share aggregate or de-identified
                    information about users with third parties and publicly for
                    product development, marketing, advertising, research or
                    similar purposes.
                </li>
            </ul>
            <p className='p1'>
                <strong> Rights of access, correction, and deletion </strong>
            </p>
            <p className='p1'>
                You can access, edit, update, or delete your Account or Personal
                Information we have collected at any time by accessing your
                account settings or emailing us at{' '}
                <span className='s2'> safetravy@gmail.com </span> . We will
                respond to your requests within a reasonable period of time, but
                no later than the time period required by law.
            </p>
            <p className='p1'>
                Please note that notwithstanding the foregoing, there may be
                circumstances in which we are unable to accommodate a request to
                edit, update, access, or delete an account profile or Personal
                Information. This includes but is not limited to:
            </p>
            <p className='p1'>
                &middot;any basis where such request can be denied under
                applicable law;
            </p>
            <p className='p1'>
                &middot;where we need to retain the information to comply with
                federal, state, or local laws or for accounting or tax purposes;
            </p>
            <p className='p1'>
                &middot;where we need to comply with a civil, criminal, or
                regulatory inquiry, investigation, subpoena, or summons by
                federal, state, or local authorities;
            </p>
            <p className='p1'>
                &middot;where we need to cooperate with law enforcement agencies
                concerning conduct or activity that the business, service
                provider, or third party reasonably and in good faith believes
                may violate federal, state, or local law;
            </p>
            <p className='p1'>
                &middot;where we need to retain information to exercise or
                defend legal claims;
            </p>
            <p className='p1'>
                &middot;where the information contains legal privilege or
                proprietary information of another party; or &middot;where
                complying with the request would compromise others&rsquo;
                privacy or other legitimate rights.
            </p>
            <p className='p1'>
                If we determine that we cannot respond to any request in any
                particular instance, we will provide you with an explanation of
                why that determination has been made and a contact point for any
                further inquiries. To protect your privacy, we will take
                commercially reasonable steps to verify your identity before
                responding to any request under this provision, including
                complying with any applicable legal requirement for verifying
                your identity.
            </p>
            <p className='p1'>
                <strong> Transfer of Information </strong>
            </p>
            <p className='p1'>
                Please note, we may store your Personal Information on servers
                located in the countries where our main offices are located. If
                such jurisdiction is outside your jurisdiction of residence, you
                consent to the transfer of your Personal Information to such
                jurisdiction for purposes of providing the Platform to you, even
                if such other jurisdiction has less protections for Personal
                Information than your jurisdiction of residence. We will ensure
                that security provisions are in place consistent with our
                obligations to maintain the security of your Personal
                Information under the laws of your jurisdiction of residence.
            </p>
            <p className='p1'>
                <strong> Retention </strong>
            </p>
            <p className='p1'>
                We retain your Personal Information as long as you maintain an
                Account on the Platform. We will cease to retain your Personal
                Information, or remove the means by which the Personal
                Information can be associated with particular individuals, as
                soon as it is reasonable to assume that &mdash;
            </p>
            <p className='p1'>
                (a) the purpose for which that Personal Information was
                collected is no longer being served by its retention; and
            </p>
            <p className='p1'>
                (b) retention is no longer necessary for legal, accounting or
                business purposes.
            </p>
            <p className='p1'>
                Please note that certain laws may require us to retain records
                of transactions or accounts for a certain period of time.
            </p>
            <p className='p1'>
                <strong> Third-Party Links </strong>
            </p>
            <p className='p1'>
                Our Platform may contain links to third-party websites. Any
                access to and use of such linked websites is not governed by
                this Policy, but instead is governed by the privacy policies of
                those third-party websites. We are not responsible for the
                information practices of such third-party websites.
            </p>
            <p className='p1'>
                <strong> Children Under 18 </strong>
            </p>
            <p className='p1'>
                Our Platform is not designed for children under 18. If we
                discover that a child under 18 has provided us with Personal
                Information, we will delete such information from our systems.
            </p>
            <p className='p1'>
                <strong> Changes to our privacy policy </strong>
            </p>
            <p className='p1'>
                This Policy is current as of the Effective Date set forth above.
                We may change this Policy from time to time, so please be sure
                to check back periodically. We will post any changes to this
                Policy on the Platform. If we make any changes to this Policy
                that materially affect our practices with regard to the Personal
                Information we have previously collected from you, we will
                endeavor to provide you with notice in advance of such change by
                highlighting the change on our Platform or providing a push
                notification through the Site (you should make sure your Site
                settings allow for such push notifications) or sending an email
                that you have provided in your Account, (for this reason you
                should make sure to update your account information promptly if
                it changes).
            </p>
            <p className='p1'>
                <strong> How to contact us </strong>
            </p>
            <p className='p1'>
                How to contact us If you have any questions about SafeTravy’s
                privacy policy, the data we hold on you or you would like to
                exercise one of your data protection rights, please do not
                hesitate to contact us: via{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    support@safetravy.com
                </span>{' '}
                or by phone <a href='tel:+350 540953'>+350 540953</a>
            </p>
        </>
    ),
    [Languages.ES]: (
        <>
            <p className='p1'>
                Esta Pol&iacute;tica de privacidad (esta "Pol&iacute;tica") se
                aplica a la plataforma de negociaci&oacute;n (incluidas las
                aplicaciones m&oacute;viles y los sitios web aplicables
                utilizados para acceder a la misma) (colectivamente, la
                "Plataforma") proporcionada por SafeTravy Limited (la "Empresa",
                "nosotros", "nos" o "nuestro"). En ella se describe el modo en
                que la Empresa recopila, utiliza y divulga los Datos Personales
                que obtenemos de los Usuarios de la Plataforma y de cualquier
                servicio de cuenta prestado a trav&eacute;s de la Plataforma,
                as&iacute; como el modo en que utilizamos y divulgamos dicha
                informaci&oacute;n. A efectos de la presente Pol&iacute;tica,
                por "Datos Personales" se entiende la informaci&oacute;n
                facilitada por un Usuario a partir de la cual puede
                determinarse, directa o indirectamente, la identidad de dicho
                Usuario.
            </p>
            <p className='p1'>
                Al registrarse y utilizar la Plataforma, usted acepta que sus
                Datos Personales se traten como se describe en la presente
                Pol&iacute;tica y en los T&eacute;rminos y Condiciones
                aplicables a la Plataforma (el "Acuerdo de Servicio"); los
                t&eacute;rminos en may&uacute;scula utilizados en el presente
                documento tendr&aacute;n el mismo significado que se establece
                en el Acuerdo de Servicio. La presente Pol&iacute;tica de
                Privacidad forma parte integrante de los{' '}
                <span className='s1'>
                    T&eacute;rminos y Condiciones de SafeTravy
                </span>
                .
            </p>
            <p className='p1'>
                <strong>&iquest;Qu&eacute; datos recogemos?</strong>
            </p>
            <p className='p1'>SafeTravy recopila los siguientes datos:</p>
            <p className='p1'>
                Datos de <strong>identificaci&oacute;n personal </strong>
                (nombre, direcci&oacute;n de correo electr&oacute;nico,
                nacionalidad, etc.)
            </p>
            <p className='p1'>
                Informaci&oacute;n de <strong>contacto </strong>
                (direcci&oacute;n de correo electr&oacute;nico)
            </p>
            <p className='p1'>
                Informaci&oacute;n <strong>financiera </strong>(datos bancarios)
            </p>
            <p className='p1'>
                Informaci&oacute;n sobre <strong>transacciones </strong>(datos
                sobre pagos efectuados por usted o recibidos de usted y otros
                datos sobre productos y servicios que nos haya comprado)
            </p>
            <p className='p1'>
                Informaci&oacute;n <strong>t&eacute;cnica </strong>
                (direcci&oacute;n del protocolo de Internet (IP), datos de
                inicio de sesi&oacute;n, tipo y versi&oacute;n del navegador,
                configuraci&oacute;n y ubicaci&oacute;n de la zona horaria,
                sistema operativo y plataforma y otras tecnolog&iacute;as de los
                dispositivos que utiliza para acceder a nuestros sitios web,
                productos y servicios).
            </p>
            <p className='p1'>
                Informaci&oacute;n <strong>sobre su perfil </strong>(nombre de
                usuario y contrase&ntilde;a, suscripciones, intereses,
                preferencias, opiniones y respuestas a encuestas).
            </p>
            <p className='p1'>
                Informaci&oacute;n de <strong>uso </strong>(incluye c&oacute;mo
                utiliza nuestro sitio web, productos y servicios)
            </p>
            <p className='p1'>
                Informaci&oacute;n de{' '}
                <strong>marketing y comunicaciones </strong>(sus preferencias a
                la hora de recibir informaci&oacute;n de marketing de nuestra
                parte y sus preferencias de comunicaci&oacute;n)
            </p>
            <p className='p1'>
                Informaci&oacute;n agregada (datos estad&iacute;sticos o
                demogr&aacute;ficos. Los datos agregados podr&iacute;an
                derivarse de sus datos personales, pero no se consideran datos
                personales desde el punto de vista legal, ya que estos datos no
                revelar&aacute;n directa ni indirectamente su identidad. Por
                ejemplo, PODR&Iacute;AMOS agregar sus Datos de uso para calcular
                el porcentaje de usuarios que acceden a una funci&oacute;n
                espec&iacute;fica del sitio web. Sin embargo, si combinamos o
                conectamos los Datos Agregados con sus datos personales de forma
                que puedan identificarle directa o indirectamente, trataremos
                los datos combinados como datos personales que se
                utilizar&aacute;n de acuerdo con esta pol&iacute;tica de
                privacidad)
            </p>
            <p className='p1'>
                <strong>
                    No recogemos ninguna Categor&iacute;a Especial de Datos
                    Personales{' '}
                </strong>
                sobre usted (esto incluye detalles sobre su raza o etnia,
                creencias religiosas o filos&oacute;ficas, vida sexual,
                orientaci&oacute;n sexual, opiniones pol&iacute;ticas,
                afiliaci&oacute;n sindical, informaci&oacute;n sobre su salud y
                datos gen&eacute;ticos y biom&eacute;tricos). Tampoco
                recopilamos informaci&oacute;n sobre condenas y delitos penales.
            </p>
            <p className='p1'>
                <strong>&iquest;C&oacute;mo recogemos sus datos?</strong>
            </p>
            <p className='p1'>
                Usted proporciona directamente a SafeTravy la mayor&iacute;a de
                los datos que recopilamos. Recogemos datos y los procesamos
                cuando usted:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Reg&iacute;strese en l&iacute;nea o solicite cualquiera de
                    nuestros planes de suscripci&oacute;n.
                </li>
                <li className='li1'>
                    Rellene voluntariamente una encuesta de clientes o
                    env&iacute;e sus comentarios en cualquiera de nuestros
                    tablones de anuncios o por correo electr&oacute;nico.
                </li>
                <li className='li1'>
                    Utilizar o ver nuestro sitio web a trav&eacute;s de las
                    cookies de su navegador.
                </li>
                <li className='li1'>
                    Suscribirse a un servicio, publicaci&oacute;n o
                    bolet&iacute;n, o solicitar que se le env&iacute;e
                    publicidad.
                </li>
                <li className='li1'>
                    P&oacute;ngase en contacto con nosotros directamente a
                    trav&eacute;s de nuestro sitio web, correo
                    electr&oacute;nico, tel&eacute;fono u otros medios de
                    comunicaci&oacute;n.
                </li>
            </ul>
            <p className='p1'>
                SafeTravy tambi&eacute;n recibir&aacute; sus datos
                indirectamente de las siguientes fuentes:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Tecnolog&iacute;as o interacciones automatizadas: A medida
                    que interact&uacute;e con nuestro sitio web, productos y
                    servicios, recopilaremos autom&aacute;ticamente Datos
                    t&eacute;cnicos sobre su equipo, acciones de
                    navegaci&oacute;n y patrones. Recopilamos estos datos
                    personales utilizando cookies, registros de servidor y otras
                    tecnolog&iacute;as similares.
                </li>
                <li className='li1'>Datos t&eacute;cnicos de:</li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    proveedores de an&aacute;lisis, como Google, con sede fuera
                    de la Uni&oacute;n Europea (UE).
                </li>
                <li className='li1'>
                    hosting y otros proveedores de servicios como Amazon con
                    sede fuera de la UE.
                </li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    Datos de contacto, financieros y de transacciones de
                    proveedores de servicios t&eacute;cnicos, de pago y de
                    entrega.
                </li>
            </ul>
            <p className='p1'>
                <strong>C&oacute;mo utilizamos su informaci&oacute;n</strong>
            </p>
            <p className='p1'>
                Utilizamos su informaci&oacute;n, incluidos sus Datos
                Personales, para los siguientes fines:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Para proporcionarle nuestra Plataforma, facilitar las
                    comunicaciones y transacciones en la Plataforma,
                    comunicarnos con usted acerca de su uso de nuestra
                    Plataforma, responder a sus consultas, cumplir con sus
                    pedidos y para otros fines de servicio al cliente;
                </li>
                <li className='li1'>
                    Para adaptar el contenido y la informaci&oacute;n que
                    podemos enviarle o mostrarle, para ofrecer
                    personalizaci&oacute;n de ubicaci&oacute;n, y ayuda e
                    instrucciones personalizadas, y para personalizar de otro
                    modo sus experiencias mientras utiliza nuestra Plataforma;
                </li>
                <li className='li1'>
                    Para comprender mejor c&oacute;mo los usuarios acceden y
                    utilizan la Plataforma, tanto de forma agregada como
                    individualizada, con el fin de mejorar nuestra Plataforma y
                    responder a los deseos y preferencias de los usuarios,
                    as&iacute; como para otros fines de investigaci&oacute;n y
                    an&aacute;lisis;
                </li>
                <li className='li1'>
                    Con fines promocionales y de marketing. Por ejemplo, podemos
                    utilizar su informaci&oacute;n, como su direcci&oacute;n de
                    correo electr&oacute;nico o n&uacute;mero de contacto
                    registrado con nosotros, para enviarle noticias y boletines,
                    ofertas especiales y promociones, para realizar sorteos y
                    concursos, o para ponernos en contacto con usted sobre
                    productos o informaci&oacute;n que pensamos que pueden
                    interesarle. Tambi&eacute;n podemos utilizar la
                    informaci&oacute;n que obtenemos sobre usted para publicitar
                    nuestra Plataforma en sitios web de terceros.
                </li>
            </ul>
            <p className='p1'>
                <strong>C&oacute;mo compartimos su informaci&oacute;n</strong>
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Afiliadas. Podemos divulgar la informaci&oacute;n que
                    recopilamos de usted a nuestras filiales o subsidiarias con
                    el &uacute;nico fin de proporcionarle la Plataforma o
                    servicios relacionados, incluidos los servicios de
                    subsitios; sin embargo, si lo hacemos, el uso y la
                    divulgaci&oacute;n de su informaci&oacute;n de
                    identificaci&oacute;n personal ser&aacute;n mantenidos por
                    dichas filiales y subsidiarias de conformidad con esta
                    Pol&iacute;tica.
                </li>
                <li className='li1'>
                    Proveedores de servicios. Podemos revelar la
                    informaci&oacute;n que recopilamos de usted a terceros
                    vendedores, proveedores de servicios, contratistas o agentes
                    que desempe&ntilde;an funciones en nuestro nombre, siempre
                    que dichos terceros hayan acordado utilizar dicha
                    informaci&oacute;n &uacute;nicamente para prestarnos
                    servicios.
                </li>
                <li className='li1'>
                    Transferencias comerciales. Si estamos en negociaciones con
                    otra empresa o entidad, o somos adquiridos por otra empresa
                    o entidad o nos fusionamos con ella, si se transfieren
                    sustancialmente todos nuestros activos a otra empresa o
                    entidad, o como parte de un procedimiento de quiebra,
                    podemos transferir la informaci&oacute;n que hemos
                    recopilado de usted a la otra empresa o entidad.
                </li>
                <li className='li1'>
                    En respuesta a un proceso legal. Tambi&eacute;n podemos
                    divulgar la informaci&oacute;n que recopilamos sobre usted
                    para cumplir con la ley, un procedimiento judicial, una
                    orden judicial u otro proceso legal, como en respuesta a una
                    citaci&oacute;n.
                </li>
                <li className='li1'>
                    Para protegernos a nosotros y a otros. Tambi&eacute;n
                    podemos revelar la informaci&oacute;n que recopilamos de
                    usted si creemos que es necesario investigar, prevenir o
                    tomar medidas en relaci&oacute;n con actividades ilegales,
                    sospechas de fraude, situaciones que impliquen amenazas
                    potenciales para la seguridad de cualquier persona,
                    violaciones de nuestro Contrato de Servicio o de esta
                    Pol&iacute;tica, o como prueba en litigios en los que
                    estemos implicados.
                </li>
                <li className='li1'>
                    Informaci&oacute;n agregada y no identificada. Podemos
                    recopilar, procesar, analizar y compartir informaci&oacute;n
                    agregada o desidentificada sobre los usuarios con terceros y
                    p&uacute;blicamente para el desarrollo de productos,
                    marketing, publicidad, investigaci&oacute;n o fines
                    similares.
                </li>
            </ul>
            <p className='p1'>
                <strong>
                    Derechos de acceso, rectificaci&oacute;n y supresi&oacute;n
                </strong>
            </p>
            <p className='p1'>
                Puede acceder, editar, actualizar o eliminar su Cuenta o la
                Informaci&oacute;n Personal que hemos recopilado en cualquier
                momento accediendo a la configuraci&oacute;n de su cuenta o
                envi&aacute;ndonos un correo electr&oacute;nico a
                safetravy@gmail.com. Responderemos a sus solicitudes en un plazo
                razonable, pero no m&aacute;s tarde del plazo exigido por la
                ley.
            </p>
            <p className='p1'>
                Tenga en cuenta que, a pesar de lo anterior, puede haber
                circunstancias en las que no podamos atender una solicitud de
                edici&oacute;n, actualizaci&oacute;n, acceso o
                eliminaci&oacute;n del perfil de una cuenta o de
                Informaci&oacute;n Personal. Esto incluye, pero no se limita a:
            </p>
            <p className='p1'>
                -cualquier fundamento por el que pueda denegarse dicha solicitud
                en virtud de la legislaci&oacute;n aplicable;
            </p>
            <p className='p1'>
                -cuando necesitemos conservar la informaci&oacute;n para cumplir
                las leyes federales, estatales o locales o con fines contables o
                fiscales;
            </p>
            <p className='p1'>
                -cuando necesitemos cumplir con una indagaci&oacute;n,
                investigaci&oacute;n, citaci&oacute;n o emplazamiento civil,
                penal o reglamentario por parte de las autoridades federales,
                estatales o locales;
            </p>
            <p className='p1'>
                -cuando necesitemos cooperar con las fuerzas de seguridad en
                relaci&oacute;n con conductas o actividades que la empresa, el
                proveedor de servicios o un tercero considere razonablemente y
                de buena fe que pueden infringir la legislaci&oacute;n federal,
                estatal o local;
            </p>
            <p className='p1'>
                -cuando necesitemos conservar informaci&oacute;n para ejercer o
                defender reclamaciones legales;
            </p>
            <p className='p1'>
                -cuando la informaci&oacute;n contenga privilegios legales o
                informaci&oacute;n propiedad de terceros; o -cuando el
                cumplimiento de la solicitud comprometa la intimidad u otros
                derechos leg&iacute;timos de terceros.
            </p>
            <p className='p1'>
                Si determinamos que no podemos responder a una solicitud en un
                caso concreto, le proporcionaremos una explicaci&oacute;n de por
                qu&eacute; se ha tomado esa determinaci&oacute;n y un punto de
                contacto para cualquier consulta adicional. Para proteger su
                privacidad, tomaremos medidas comercialmente razonables para
                verificar su identidad antes de responder a cualquier solicitud
                en virtud de esta disposici&oacute;n, incluido el cumplimiento
                de cualquier requisito legal aplicable para verificar su
                identidad.
            </p>
            <p className='p1'>
                <strong>Transferencia de informaci&oacute;n</strong>
            </p>
            <p className='p1'>
                Tenga en cuenta que podemos almacenar sus Datos Personales en
                servidores ubicados en los pa&iacute;ses donde se encuentran
                nuestras oficinas principales. Si dicha jurisdicci&oacute;n se
                encuentra fuera de su jurisdicci&oacute;n de residencia, usted
                consiente la transferencia de su Informaci&oacute;n Personal a
                dicha jurisdicci&oacute;n con el fin de proporcionarle la
                Plataforma, incluso si dicha jurisdicci&oacute;n tiene menos
                protecciones para la Informaci&oacute;n Personal que su
                jurisdicci&oacute;n de residencia. Nos aseguraremos de que las
                disposiciones de seguridad sean coherentes con nuestras
                obligaciones de mantener la seguridad de su Informaci&oacute;n
                Personal bajo las leyes de su jurisdicci&oacute;n de residencia.
            </p>
            <p className='p1'>
                <strong>Retenci&oacute;n</strong>
            </p>
            <p className='p1'>
                Conservaremos sus Datos Personales mientras usted mantenga una
                Cuenta en la Plataforma. Dejaremos de conservar sus Datos
                Personales, o eliminaremos los medios por los que los Datos
                Personales pueden asociarse a personas concretas, en cuanto sea
                razonable suponer que -.
            </p>
            <p className='p1'>
                (a) la finalidad para la que se recogieron los Datos Personales
                ya no se consigue con su conservaci&oacute;n; y
            </p>
            <p className='p1'>
                (b) su conservaci&oacute;n ya no es necesaria para fines
                legales, contables o comerciales.
            </p>
            <p className='p1'>
                Tenga en cuenta que determinadas leyes pueden obligarnos a
                conservar los registros de transacciones o cuentas durante un
                determinado periodo de tiempo.
            </p>
            <p className='p1'>
                <strong>Enlaces de terceros</strong>
            </p>
            <p className='p1'>
                Nuestra Plataforma puede contener enlaces a sitios web de
                terceros. El acceso y uso de dichos sitios web enlazados no se
                rige por la presente Pol&iacute;tica, sino por las
                pol&iacute;ticas de privacidad de dichos sitios web de terceros.
                No somos responsables de las pr&aacute;cticas de
                informaci&oacute;n de dichos sitios web de terceros.
            </p>
            <p className='p1'>
                <strong>Menores de 18 a&ntilde;os</strong>
            </p>
            <p className='p1'>
                Nuestra Plataforma no est&aacute; dise&ntilde;ada para menores
                de 18 a&ntilde;os. Si descubrimos que un menor de 18 a&ntilde;os
                nos ha proporcionado Informaci&oacute;n Personal, eliminaremos
                dicha informaci&oacute;n de nuestros sistemas.
            </p>
            <p className='p1'>
                <strong>
                    Cambios en nuestra pol&iacute;tica de privacidad
                </strong>
            </p>
            <p className='p1'>
                Esta Pol&iacute;tica est&aacute; vigente a partir de la Fecha de
                entrada en vigor indicada anteriormente. Podemos cambiar esta
                Pol&iacute;tica de vez en cuando, as&iacute; que por favor
                aseg&uacute;rese de comprobarla peri&oacute;dicamente.
                Publicaremos cualquier modificaci&oacute;n de esta
                Pol&iacute;tica en la Plataforma. Si realizamos alg&uacute;n
                cambio en esta Pol&iacute;tica que afecte materialmente a
                nuestras pr&aacute;cticas con respecto a la Informaci&oacute;n
                Personal que hayamos recopilado previamente de usted, nos
                esforzaremos por avisarle con antelaci&oacute;n de dicho cambio
                destacando el cambio en nuestra Plataforma o proporcionando una
                notificaci&oacute;n push a trav&eacute;s del Sitio (debe
                asegurarse de que la configuraci&oacute;n de su Sitio permite
                dichas notificaciones push) o enviando un correo
                electr&oacute;nico que haya proporcionado en su Cuenta, (por
                este motivo, debe asegurarse de actualizar la informaci&oacute;n
                de su cuenta r&aacute;pidamente si cambia).
            </p>
            <p className='p1'>
                <strong>Cómo ponerse en contacto con nosotros</strong>
            </p>
            <p className='p1'>
                Si tiene alguna pregunta sobre la política de privacidad de
                SafeTravy, los datos que tenemos sobre usted o desea ejercer
                alguno de sus derechos de protección de datos, no dude en
                ponerse en contacto con nosotros: por correo electrónico{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    support@safetravy.com
                </span>{' '}
                o por teléfono <a href='tel:+350 54095364'>+350 54095364</a>.
            </p>
            <p className='p1'>
                Env&iacute;enos un correo electr&oacute;nico a:{' '}
                <span className='s2'>safetravy@gmail.com</span>
            </p>
            <p className='p2'>&nbsp;</p>
        </>
    ),
    [Languages.FR]: (
        <>
            <p className='p1'>
                La pr&eacute;sente politique de confidentialit&eacute; (la "
                Politique ") s'applique &agrave; la plateforme de trading (y
                compris les applications mobiles et les sites web
                utilis&eacute;s pour y acc&eacute;der) (collectivement la "
                Plateforme ") fournie par SafeTravy Limited (la "
                Soci&eacute;t&eacute; ", " nous ", " notre " ou " nos "). Elle
                d&eacute;crit la mani&egrave;re dont la Soci&eacute;t&eacute;
                recueille, utilise et divulgue les informations personnelles
                qu'elle obtient des utilisateurs de la Plateforme et de tout
                service de compte fourni par l'interm&eacute;diaire de la
                Plateforme, ainsi que la mani&egrave;re dont elle utilise et
                divulgue ces informations. Aux fins de la pr&eacute;sente
                politique, on entend par "informations personnelles" les
                informations fournies par un utilisateur &agrave; partir
                desquelles l'identit&eacute; de cet utilisateur peut &ecirc;tre
                directement ou indirectement d&eacute;termin&eacute;e.
            </p>
            <p className='p1'>
                En vous inscrivant sur la plate-forme et en l'utilisant, vous
                acceptez que vos informations personnelles soient
                trait&eacute;es comme d&eacute;crit dans la pr&eacute;sente
                politique et dans les conditions g&eacute;n&eacute;rales
                applicables &agrave; la plate-forme (le "contrat de service") ;
                les termes en majuscules utilis&eacute;s dans le pr&eacute;sent
                document ont la m&ecirc;me signification que celle qui leur est
                donn&eacute;e dans le contrat de service. La pr&eacute;sente
                politique de confidentialit&eacute; fait partie
                int&eacute;grante des{' '}
                <span className='s1'>
                    conditions g&eacute;n&eacute;rales de SafeTravy
                </span>
                .
            </p>
            <p className='p1'>
                <strong>
                    Quelles sont les donn&eacute;es collect&eacute;es ?
                </strong>
            </p>
            <p className='p1'>
                SafeTravy collecte les donn&eacute;es suivantes :
            </p>
            <p className='p1'>
                Informations d'<strong>identification personnelle </strong>(nom,
                adresse &eacute;lectronique, nationalit&eacute;, etc.)
            </p>
            <p className='p1'>
                Informations de <strong>contact </strong>(adresse
                &eacute;lectronique)
            </p>
            <p className='p1'>
                Informations <strong>financi&egrave;res </strong>
                (coordonn&eacute;es bancaires)
            </p>
            <p className='p1'>
                Informations sur les <strong>transactions </strong>
                (d&eacute;tails sur les paiements effectu&eacute;s en votre
                faveur ou par vous et autres d&eacute;tails sur les produits et
                services que vous avez achet&eacute;s aupr&egrave;s de nous)
            </p>
            <p className='p1'>
                Informations <strong>techniques </strong>(adresse IP,
                donn&eacute;es de connexion, type et version du navigateur,
                r&eacute;glage du fuseau horaire et localisation, syst&egrave;me
                d'exploitation et plateforme et autres technologies sur les
                appareils que vous utilisez pour acc&eacute;der &agrave; nos
                sites web, produits et services).
            </p>
            <p className='p1'>
                Informations sur <strong>le profil </strong>(nom d'utilisateur
                et mot de passe, achats d'abonnements effectu&eacute;s par vous,
                vos int&eacute;r&ecirc;ts, vos pr&eacute;f&eacute;rences, vos
                commentaires et vos r&eacute;ponses &agrave; des enqu&ecirc;tes)
            </p>
            <p className='p1'>
                Informations relatives &agrave; l<strong>'utilisation </strong>
                (notamment la mani&egrave;re dont vous utilisez notre site web,
                nos produits et nos services)
            </p>
            <p className='p1'>
                Informations relatives au{' '}
                <strong>marketing et &agrave; la communication </strong>(vos
                pr&eacute;f&eacute;rences en mati&egrave;re de marketing et de
                communication)
            </p>
            <p className='p1'>
                Informations <strong>agr&eacute;g&eacute;es </strong>
                (donn&eacute;es statistiques ou d&eacute;mographiques). Les
                donn&eacute;es agr&eacute;g&eacute;es peuvent &ecirc;tre
                d&eacute;riv&eacute;es de vos donn&eacute;es personnelles mais
                ne sont pas consid&eacute;r&eacute;es comme des donn&eacute;es
                personnelles au sens de la loi car ces donn&eacute;es ne
                r&eacute;v&egrave;lent pas directement ou indirectement votre
                identit&eacute;. Par exemple, nous PEUVONS agr&eacute;ger vos
                donn&eacute;es d'utilisation pour calculer le pourcentage
                d'utilisateurs acc&eacute;dant &agrave; une fonction
                sp&eacute;cifique du site web. Toutefois, si nous combinons ou
                connectons les donn&eacute;es agr&eacute;g&eacute;es avec vos
                donn&eacute;es personnelles de mani&egrave;re &agrave; ce
                qu'elles puissent directement ou indirectement vous identifier,
                nous traitons les donn&eacute;es combin&eacute;es comme des
                donn&eacute;es personnelles qui seront utilis&eacute;es
                conform&eacute;ment &agrave; la pr&eacute;sente politique de
                confidentialit&eacute;.)
            </p>
            <p className='p1'>
                <strong>
                    Nous ne recueillons aucune cat&eacute;gorie
                    particuli&egrave;re de donn&eacute;es &agrave;
                    caract&egrave;re personnel{' '}
                </strong>
                vous concernant (notamment des informations sur votre race ou
                votre appartenance ethnique, vos croyances religieuses ou
                philosophiques, votre vie sexuelle, votre orientation sexuelle,
                vos opinions politiques, votre appartenance &agrave; un
                syndicat, des informations sur votre sant&eacute;, ainsi que des
                donn&eacute;es g&eacute;n&eacute;tiques et biom&eacute;triques).
                Nous ne recueillons pas non plus d'informations sur les
                condamnations p&eacute;nales et les infractions.
            </p>
            <p className='p1'>
                <strong>Comment recueillons-nous vos donn&eacute;es ?</strong>
            </p>
            <p className='p1'>
                Vous fournissez directement &agrave; SafeTravy la plupart des
                donn&eacute;es que nous collectons. Nous collectons et traitons
                des donn&eacute;es lorsque vous :
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Inscrivez-vous en ligne ou commandez l'une de nos formules
                    d'abonnement.
                </li>
                <li className='li1'>
                    R&eacute;pondre volontairement &agrave; une enqu&ecirc;te
                    aupr&egrave;s des clients ou fournir un retour d'information
                    sur l'un de nos tableaux d'affichage ou par courrier
                    &eacute;lectronique.
                </li>
                <li className='li1'>
                    Utiliser ou consulter notre site web via les cookies de
                    votre navigateur.
                </li>
                <li className='li1'>
                    S'abonner &agrave; un service, &agrave; une publication,
                    &agrave; une lettre d'information ou demander &agrave; ce
                    que des actions de marketing vous soient envoy&eacute;es.
                </li>
                <li className='li1'>
                    Nous contacter directement via notre site web, par courriel,
                    par t&eacute;l&eacute;phone ou par d'autres moyens de
                    communication.
                </li>
            </ul>
            <p className='p1'>
                SafeTravy recevra &eacute;galement vos donn&eacute;es
                indirectement des sources suivantes :
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Technologies ou interactions automatis&eacute;es : Lorsque
                    vous interagissez avec notre site web, nos produits et nos
                    services, nous recueillons automatiquement des
                    donn&eacute;es techniques sur votre &eacute;quipement, vos
                    actions et vos habitudes de navigation. Nous collectons ces
                    donn&eacute;es personnelles en utilisant des cookies, des
                    journaux de serveur et d'autres technologies similaires.
                </li>
                <li className='li1'>Donn&eacute;es techniques de :</li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    des fournisseurs de services d'analyse, tels que Google,
                    bas&eacute;s en dehors de l'Union europ&eacute;enne (UE).
                </li>
                <li className='li1'>
                    l'h&eacute;bergement et d'autres fournisseurs de services
                    tels qu'Amazon bas&eacute;s en dehors de l'UE.
                </li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    Donn&eacute;es de contact, financi&egrave;res et de
                    transaction provenant de fournisseurs de services
                    techniques, de paiement et de livraison.
                </li>
            </ul>
            <p className='p1'>
                <strong>Comment nous utilisons vos informations</strong>
            </p>
            <p className='p1'>
                Nous utilisons vos informations, y compris vos donn&eacute;es
                personnelles, aux fins suivantes :
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Pour vous fournir notre plate-forme, pour faciliter les
                    communications et les transactions sur la plate-forme, pour
                    communiquer avec vous au sujet de votre utilisation de notre
                    plate-forme, pour r&eacute;pondre &agrave; vos demandes,
                    pour ex&eacute;cuter vos commandes et &agrave; d'autres fins
                    de service &agrave; la client&egrave;le ;
                </li>
                <li className='li1'>
                    Pour adapter le contenu et les informations que nous pouvons
                    vous envoyer ou afficher, pour offrir une personnalisation
                    de l'emplacement, une aide et des instructions
                    personnalis&eacute;es, et pour personnaliser vos
                    exp&eacute;riences lors de l'utilisation de notre Plateforme
                    ;
                </li>
                <li className='li1'>
                    Mieux comprendre comment les utilisateurs acc&egrave;dent
                    &agrave; la plate-forme et l'utilisent, &agrave; la fois sur
                    une base agr&eacute;g&eacute;e et individualis&eacute;e,
                    afin d'am&eacute;liorer notre plate-forme et de
                    r&eacute;pondre aux souhaits et pr&eacute;f&eacute;rences
                    des utilisateurs, ainsi qu'&agrave; d'autres fins de
                    recherche et d'analyse ;
                </li>
                <li className='li1'>
                    &Agrave; des fins de marketing et de promotion. Par exemple,
                    nous pouvons utiliser vos informations, telles que votre
                    adresse &eacute;lectronique ou le num&eacute;ro de contact
                    que vous avez enregistr&eacute; chez nous, pour vous envoyer
                    des nouvelles et des bulletins d'information, des offres
                    sp&eacute;ciales et des promotions, pour organiser des
                    tirages au sort et des concours, ou pour vous contacter
                    &agrave; propos de produits ou d'informations que nous
                    pensons susceptibles de vous int&eacute;resser. Nous pouvons
                    &eacute;galement utiliser les informations que nous obtenons
                    &agrave; votre sujet pour nous aider &agrave; faire de la
                    publicit&eacute; pour notre plate-forme sur des sites web de
                    tiers.
                </li>
            </ul>
            <p className='p1'>
                <strong>Comment nous partageons vos informations</strong>
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Soci&eacute;t&eacute;s affili&eacute;es. Nous pouvons
                    divulguer les informations que nous recueillons
                    aupr&egrave;s de vous &agrave; nos soci&eacute;t&eacute;s
                    affili&eacute;es ou filiales dans le seul but de vous
                    fournir la plate-forme ou des services connexes, y compris
                    des services de sous-site ; toutefois, si nous le faisons,
                    l'utilisation et la divulgation de vos informations
                    personnellement identifiables seront maintenues par ces
                    soci&eacute;t&eacute;s affili&eacute;es et filiales
                    conform&eacute;ment &agrave; la pr&eacute;sente politique.
                </li>
                <li className='li1'>
                    Prestataires de services. Nous pouvons divulguer les
                    informations que nous recueillons aupr&egrave;s de vous
                    &agrave; des fournisseurs, prestataires de services,
                    sous-traitants ou agents tiers qui exercent des fonctions en
                    notre nom, &agrave; condition que ces tiers aient
                    accept&eacute; de n'utiliser ces informations que pour nous
                    fournir des services.
                </li>
                <li className='li1'>
                    Transferts d'activit&eacute;s. Si nous sommes en
                    n&eacute;gociation avec une autre soci&eacute;t&eacute; ou
                    entit&eacute;, si nous sommes acquis par une autre
                    soci&eacute;t&eacute; ou entit&eacute; ou si nous fusionnons
                    avec elle, si la quasi-totalit&eacute; de nos actifs sont
                    transf&eacute;r&eacute;s &agrave; une autre
                    soci&eacute;t&eacute; ou entit&eacute;, ou dans le cadre
                    d'une proc&eacute;dure de faillite, nous pouvons
                    transf&eacute;rer les informations que nous avons
                    collect&eacute;es aupr&egrave;s de vous &agrave; l'autre
                    soci&eacute;t&eacute; ou entit&eacute;.
                </li>
                <li className='li1'>
                    En r&eacute;ponse &agrave; une proc&eacute;dure judiciaire.
                    Nous pouvons &eacute;galement divulguer les informations que
                    nous recueillons aupr&egrave;s de vous afin de nous
                    conformer &agrave; la loi, &agrave; une proc&eacute;dure
                    judiciaire, &agrave; une ordonnance d'un tribunal ou
                    &agrave; toute autre proc&eacute;dure l&eacute;gale, par
                    exemple en r&eacute;ponse &agrave; une citation &agrave;
                    compara&icirc;tre.
                </li>
                <li className='li1'>
                    Pour nous prot&eacute;ger et prot&eacute;ger les autres.
                    Nous pouvons &eacute;galement divulguer les informations que
                    nous recueillons aupr&egrave;s de vous si nous estimons
                    qu'il est n&eacute;cessaire d'enqu&ecirc;ter, de
                    pr&eacute;venir ou de prendre des mesures concernant des
                    activit&eacute;s ill&eacute;gales, des soup&ccedil;ons de
                    fraude, des situations impliquant des menaces potentielles
                    pour la s&eacute;curit&eacute; de toute personne, des
                    violations de notre accord de service ou de la
                    pr&eacute;sente politique, ou comme preuve dans le cadre
                    d'un litige dans lequel nous sommes impliqu&eacute;s.
                </li>
                <li className='li1'>
                    Informations globales et d&eacute;personnalis&eacute;es.
                    Nous pouvons collecter, traiter, analyser et partager des
                    informations globales ou d&eacute;personnalis&eacute;es sur
                    les utilisateurs avec des tiers et publiquement pour le
                    d&eacute;veloppement de produits, le marketing, la
                    publicit&eacute;, la recherche ou d'autres objectifs
                    similaires.
                </li>
            </ul>
            <p className='p1'>
                <strong>
                    Droits d'acc&egrave;s, de correction et de suppression
                </strong>
            </p>
            <p className='p1'>
                Vous pouvez acc&eacute;der, modifier, mettre &agrave; jour ou
                supprimer votre compte ou les informations personnelles que nous
                avons collect&eacute;es &agrave; tout moment en acc&eacute;dant
                aux param&egrave;tres de votre compte ou en nous envoyant un
                courrier &eacute;lectronique &agrave; l'adresse
                safetravy@gmail.com. Nous r&eacute;pondrons &agrave; vos
                demandes dans un d&eacute;lai raisonnable, mais au plus tard
                dans le d&eacute;lai requis par la loi.
            </p>
            <p className='p1'>
                Veuillez noter que, nonobstant ce qui pr&eacute;c&egrave;de, il
                peut y avoir des circonstances dans lesquelles nous ne sommes
                pas en mesure d'acc&eacute;der &agrave; une demande de
                modification, de mise &agrave; jour, d'acc&egrave;s ou de
                suppression d'un profil de compte ou d'informations
                personnelles. Ceci inclut, mais n'est pas limit&eacute; &agrave;
                :
            </p>
            <p className='p1'>
                -tout motif pour lequel une telle demande peut &ecirc;tre
                refus&eacute;e en vertu du droit applicable ;
            </p>
            <p className='p1'>
                -lorsque nous devons conserver les informations pour nous
                conformer aux lois f&eacute;d&eacute;rales, nationales ou
                locales ou &agrave; des fins comptables ou fiscales ;
            </p>
            <p className='p1'>
                -lorsque nous devons nous conformer &agrave; une enqu&ecirc;te
                civile, criminelle ou r&eacute;glementaire, &agrave; une
                citation &agrave; compara&icirc;tre ou &agrave; une assignation
                par les autorit&eacute;s f&eacute;d&eacute;rales,
                &eacute;tatiques ou locales ;
            </p>
            <p className='p1'>
                -lorsque nous devons coop&eacute;rer avec des organismes
                charg&eacute;s de l'application de la loi concernant une
                conduite ou une activit&eacute; dont l'entreprise, le
                fournisseur de services ou un tiers estime raisonnablement et en
                toute bonne foi qu'elle peut enfreindre la l&eacute;gislation
                f&eacute;d&eacute;rale, nationale ou locale ;
            </p>
            <p className='p1'>
                -lorsque nous devons conserver des informations pour exercer ou
                d&eacute;fendre des droits l&eacute;gaux ;
            </p>
            <p className='p1'>
                -lorsque les informations contiennent un privil&egrave;ge
                l&eacute;gal ou des informations exclusives d'une autre partie ;
                ou - lorsque le respect de la demande compromettrait la vie
                priv&eacute;e ou d'autres droits l&eacute;gitimes d'autrui.
            </p>
            <p className='p1'>
                Si nous d&eacute;cidons que nous ne pouvons pas r&eacute;pondre
                &agrave; une demande dans un cas particulier, nous vous
                expliquerons les raisons de cette d&eacute;cision et vous
                indiquerons un point de contact pour toute autre question. Afin
                de prot&eacute;ger votre vie priv&eacute;e, nous prendrons des
                mesures commercialement raisonnables pour v&eacute;rifier votre
                identit&eacute; avant de r&eacute;pondre &agrave; toute demande
                en vertu de cette disposition, y compris en nous conformant
                &agrave; toute exigence l&eacute;gale applicable en
                mati&egrave;re de v&eacute;rification de votre identit&eacute;.
            </p>
            <p className='p1'>
                <strong>Transfert d'informations</strong>
            </p>
            <p className='p1'>
                Veuillez noter que nous pouvons stocker vos informations
                personnelles sur des serveurs situ&eacute;s dans les pays
                o&ugrave; se trouvent nos bureaux principaux. Si cette
                juridiction est situ&eacute;e en dehors de votre juridiction de
                r&eacute;sidence, vous consentez au transfert de vos
                informations personnelles vers cette juridiction afin de vous
                fournir la plate-forme, m&ecirc;me si cette autre juridiction
                dispose de moins de protections pour les informations
                personnelles que votre juridiction de r&eacute;sidence. Nous
                veillerons &agrave; ce que des dispositions de
                s&eacute;curit&eacute; soient en place conform&eacute;ment
                &agrave; nos obligations de maintenir la s&eacute;curit&eacute;
                de vos informations personnelles en vertu des lois de votre
                juridiction de r&eacute;sidence.
            </p>
            <p className='p1'>
                <strong>R&eacute;tention</strong>
            </p>
            <p className='p1'>
                Nous conservons vos informations personnelles aussi longtemps
                que vous avez un compte sur la plate-forme. Nous cesserons de
                conserver vos informations personnelles, ou supprimerons les
                moyens par lesquels les informations personnelles peuvent
                &ecirc;tre associ&eacute;es &agrave; des individus particuliers,
                d&egrave;s qu'il est raisonnable de supposer que les
                informations personnelles ne sont plus associ&eacute;es &agrave;
                des individus particuliers.
            </p>
            <p className='p1'>
                (a) la finalit&eacute; pour laquelle ces informations
                personnelles ont &eacute;t&eacute; collect&eacute;es n'est plus
                servie par leur conservation ; et
            </p>
            <p className='p1'>
                (b) la conservation n'est plus n&eacute;cessaire &agrave; des
                fins juridiques, comptables ou commerciales.
            </p>
            <p className='p1'>
                Veuillez noter que certaines lois peuvent nous obliger &agrave;
                conserver des enregistrements de transactions ou de comptes
                pendant une certaine p&eacute;riode.
            </p>
            <p className='p1'>
                <strong>Liens avec des tiers</strong>
            </p>
            <p className='p1'>
                Notre Plateforme peut contenir des liens vers des sites web de
                tiers. L'acc&egrave;s &agrave; ces sites web et leur utilisation
                ne sont pas r&eacute;gis par la pr&eacute;sente politique, mais
                par les politiques de confidentialit&eacute; de ces sites web
                tiers. Nous ne sommes pas responsables des pratiques
                d'information de ces sites web tiers.
            </p>
            <p className='p1'>
                <strong>Enfants de moins de 18 ans</strong>
            </p>
            <p className='p1'>
                Notre plateforme n'est pas con&ccedil;ue pour les enfants de
                moins de 18 ans. Si nous d&eacute;couvrons qu'un enfant de moins
                de 18 ans nous a fourni des informations personnelles, nous
                supprimerons ces informations de nos syst&egrave;mes.
            </p>
            <p className='p1'>
                <strong>
                    Modifications de notre politique de confidentialit&eacute;
                </strong>
            </p>
            <p className='p1'>
                La pr&eacute;sente politique est en vigueur &agrave; la date
                d'entr&eacute;e en vigueur indiqu&eacute;e ci-dessus. Nous
                pouvons modifier la pr&eacute;sente politique de temps &agrave;
                autre, veuillez donc la consulter r&eacute;guli&egrave;rement.
                Nous publierons toute modification de la pr&eacute;sente
                politique sur la plate-forme. Si nous apportons des
                modifications &agrave; la pr&eacute;sente Politique qui
                affectent mat&eacute;riellement nos pratiques en ce qui concerne
                les Informations personnelles que nous avons
                pr&eacute;c&eacute;demment collect&eacute;es aupr&egrave;s de
                vous, nous nous efforcerons de vous avertir &agrave; l'avance de
                ces modifications en les mettant en &eacute;vidence sur notre
                Plateforme ou en fournissant une notification push via le Site
                (vous devez vous assurer que les param&egrave;tres de votre Site
                autorisent de telles notifications push) ou en envoyant un
                e-mail que vous avez fourni dans votre Compte (pour cette
                raison, vous devez vous assurer de mettre &agrave; jour
                rapidement les informations de votre compte si elles changent).
            </p>
            <p className='p1'>
                <strong>Comment nous contacter</strong>
            </p>
            <p className='p1'>
                Si vous avez des questions concernant la politique de
                confidentialité de SafeTravy, les données que nous détenons sur
                vous ou si vous souhaitez exercer l'un de vos droits en matière
                de protection des données, n'hésitez pas à nous contacter: par
                courriel{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    safetravy@gmail.com
                </span>{' '}
                ou par téléphone <a href='tel:+350 54095364'>+350 54095364</a>.
            </p>

            <p className='p2'>&nbsp;</p>
        </>
    ),
    [Languages.DE]: (
        <>
            <p className='p1'>
                Diese Datenschutzrichtlinie (diese "Richtlinie") gilt f&uuml;r
                die von SafeTravy Limited (das "Unternehmen", "wir", "uns" oder
                "unser") zur Verf&uuml;gung gestellte Handelsplattform
                (einschlie&szlig;lich etwaiger mobiler Anwendungen und Websites,
                die f&uuml;r den Zugriff auf dieselbe verwendet werden)
                (zusammen die "Plattform"). Sie beschreibt, wie das Unternehmen
                personenbezogene Daten, die wir von Nutzern der Plattform und
                von &uuml;ber die Plattform bereitgestellten Kontodiensten
                erhalten, sammelt, verwendet und offenlegt und wie wir diese
                Daten verwenden und offenlegen. F&uuml;r die Zwecke dieser
                Richtlinie bezieht sich der Begriff "personenbezogene Daten" auf
                Informationen, die von einem Nutzer bereitgestellt werden und
                aus denen die Identit&auml;t dieses Nutzers direkt oder indirekt
                ermittelt werden kann.
            </p>
            <p className='p2'>&nbsp;</p>
            <p className='p1'>
                Durch die Registrierung f&uuml;r die Plattform und deren Nutzung
                erkl&auml;ren Sie sich damit einverstanden, dass Ihre
                pers&ouml;nlichen Daten wie in dieser Richtlinie und den
                f&uuml;r die Plattform geltenden Gesch&auml;ftsbedingungen (der
                "Dienstleistungsvertrag") beschrieben behandelt werden; hier
                verwendete Begriffe in Gro&szlig;buchstaben haben dieselbe
                Bedeutung wie in dem Dienstleistungsvertrag. Diese
                Datenschutzrichtlinie ist ein integraler Bestandteil der{' '}
                <span className='s1'>
                    Allgemeinen Gesch&auml;ftsbedingungen von SafeTravy
                </span>
                .
            </p>
            <p className='p1'>
                <strong>Welche Daten sammeln wir?</strong>
            </p>
            <p className='p1'>SafeTravy sammelt die folgenden Daten:</p>
            <p className='p1'>
                <strong>Pers&ouml;nliche </strong>Identifikationsdaten (Name,
                E-Mail-Adresse, Staatsangeh&ouml;rigkeit usw.)
            </p>
            <p className='p1'>Kontaktinformationen (E-Mail Adresse)</p>
            <p className='p1'>
                <strong>Finanzielle </strong>Informationen (Kontodaten)
            </p>
            <p className='p1'>
                Transaktionsinformationen (Details &uuml;ber Zahlungen an und
                von Ihnen und andere Details &uuml;ber Produkte und
                Dienstleistungen, die Sie bei uns gekauft haben)
            </p>
            <p className='p1'>
                <strong>Technische </strong>Informationen (Internetprotokoll
                (IP)-Adresse, Anmeldedaten, Browsertyp und -version,
                Zeitzoneneinstellung und Standort, Betriebssystem und Plattform
                sowie andere Technologien auf den Ger&auml;ten, die Sie f&uuml;r
                den Zugriff auf unsere Websites, Produkte und Dienstleistungen
                verwenden)
            </p>
            <p className='p1'>
                Profilinformationen (Benutzername und Passwort, von Ihnen
                gekaufte Abonnements, Ihre Interessen, Vorlieben, Feedback und
                Umfrageantworten)
            </p>
            <p className='p1'>
                Nutzungsdaten (z. B. wie Sie unsere Website, Produkte und
                Dienstleistungen nutzen)
            </p>
            <p className='p1'>
                <strong>Marketing- und </strong>Kommunikationsinformationen
                (Ihre Pr&auml;ferenzen f&uuml;r den Erhalt von Marketingmaterial
                von uns und Ihre Kommunikationspr&auml;ferenzen)
            </p>
            <p className='p1'>
                <strong>Aggregierte </strong>Informationen (statistische oder
                demografische Daten). Zusammengefasste Daten k&ouml;nnen aus
                Ihren personenbezogenen Daten abgeleitet werden, gelten aber
                rechtlich nicht als personenbezogene Daten, da diese Daten weder
                direkt noch indirekt Ihre Identit&auml;t preisgeben. Wir
                K&Ouml;NNEN zum Beispiel Ihre Nutzungsdaten zusammenfassen, um
                den Prozentsatz der Nutzer zu berechnen, die auf eine bestimmte
                Funktion der Website zugreifen. Wenn wir jedoch zusammengefasste
                Daten mit Ihren personenbezogenen Daten kombinieren oder
                verbinden, so dass sie Sie direkt oder indirekt identifizieren
                k&ouml;nnen, behandeln wir die kombinierten Daten als
                personenbezogene Daten, die in &Uuml;bereinstimmung mit dieser
                Datenschutzrichtlinie verwendet werden)
            </p>
            <p className='p1'>
                <strong>
                    Wir erheben keine besonderen Kategorien personenbezogener
                    Daten{' '}
                </strong>
                &uuml;ber Sie (dazu geh&ouml;ren Angaben zu Ihrer Ethnie, Ihren
                religi&ouml;sen oder philosophischen &Uuml;berzeugungen, Ihrem
                Sexualleben, Ihrer sexuellen Orientierung, Ihren politischen
                Ansichten, Ihrer Mitgliedschaft in einer Gewerkschaft,
                Informationen &uuml;ber Ihre Gesundheit sowie genetische und
                biometrische Daten). Auch sammeln wir keine Informationen
                &uuml;ber strafrechtliche Verurteilungen und Straftaten.
            </p>
            <p className='p1'>
                <strong>Wie sammeln wir Ihre Daten?</strong>
            </p>
            <p className='p1'>
                Die meisten der von uns erfassten Daten werden SafeTravy direkt
                von Ihnen zur Verf&uuml;gung gestellt. Wir sammeln Daten und
                verarbeiten Daten, wenn Sie:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Registrieren Sie sich online oder bestellen Sie eines
                    unserer Abonnements.
                </li>
                <li className='li1'>
                    Freiwillig eine Kundenumfrage ausf&uuml;llen oder auf einem
                    unserer Messageboards oder per E-Mail Feedback geben.
                </li>
                <li className='li1'>
                    Sie k&ouml;nnen unsere Website &uuml;ber die Cookies Ihres
                    Browsers nutzen oder ansehen.
                </li>
                <li className='li1'>
                    Abonnieren Sie einen Dienst, eine Ver&ouml;ffentlichung,
                    einen Newsletter oder fordern Sie die Zusendung von
                    Marketingmaterial an.
                </li>
                <li className='li1'>
                    Kontaktieren Sie uns direkt &uuml;ber unsere Website, per
                    E-Mail, Telefon oder andere Kommunikationsmittel.
                </li>
            </ul>
            <p className='p1'>
                SafeTravy erh&auml;lt Ihre Daten auch indirekt aus den folgenden
                Quellen:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Automatisierte Technologien oder Interaktionen: Wenn Sie mit
                    unserer Website, unseren Produkten und Diensten
                    interagieren, sammeln wir automatisch technische Daten
                    &uuml;ber Ihre Ger&auml;te, Browsing-Aktionen und Muster.
                    Wir erfassen diese personenbezogenen Daten mithilfe von
                    Cookies, Serverprotokollen und anderen &auml;hnlichen
                    Technologien.
                </li>
                <li className='li1'>Technische Daten von:</li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    Analytik-Anbieter wie Google mit Sitz au&szlig;erhalb der
                    Europ&auml;ischen Union (EU).
                </li>
                <li className='li1'>
                    Hosting- und andere Dienstleistungsanbieter wie Amazon mit
                    Sitz au&szlig;erhalb der EU.
                </li>
            </ul>
            <ul className='ul1'>
                <li className='li1'>
                    Kontakt-, Finanz- und Transaktionsdaten von Anbietern von
                    technischen, Zahlungs- und Lieferdiensten.
                </li>
            </ul>
            <p className='p1'>
                <strong>Wie wir Ihre Informationen verwenden</strong>
            </p>
            <p className='p1'>
                Wir verwenden Ihre Daten, einschlie&szlig;lich Ihrer
                pers&ouml;nlichen Daten, f&uuml;r die folgenden Zwecke:
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Um Ihnen unsere Plattform zur Verf&uuml;gung zu stellen, um
                    die Kommunikation und Transaktionen auf der Plattform zu
                    erleichtern, um mit Ihnen &uuml;ber Ihre Nutzung unserer
                    Plattform zu kommunizieren, um Ihre Anfragen zu beantworten,
                    um Ihre Bestellungen auszuf&uuml;hren und f&uuml;r andere
                    Kundendienstzwecke;
                </li>
                <li className='li1'>
                    Um die Inhalte und Informationen, die wir an Sie senden oder
                    anzeigen, auf Sie zuzuschneiden, um eine individuelle
                    Standortanpassung und personalisierte Hilfe und Anweisungen
                    anzubieten und um Ihre Erfahrungen bei der Nutzung unserer
                    Plattform anderweitig zu personalisieren;
                </li>
                <li className='li1'>
                    Um besser zu verstehen, wie Nutzer auf die Plattform
                    zugreifen und sie nutzen, sowohl auf aggregierter als auch
                    auf individueller Basis, um unsere Plattform zu verbessern
                    und auf die W&uuml;nsche und Vorlieben der Nutzer zu
                    reagieren, sowie f&uuml;r andere Forschungs- und
                    Analysezwecke;
                </li>
                <li className='li1'>
                    F&uuml;r Marketing- und Werbezwecke. Wir k&ouml;nnen Ihre
                    Daten, wie z. B. Ihre E-Mail-Adresse oder Ihre bei uns
                    registrierte Kontaktnummer, verwenden, um Ihnen Nachrichten
                    und Newsletter, Sonderangebote und Werbeaktionen zuzusenden,
                    Verlosungen und Wettbewerbe durchzuf&uuml;hren oder Sie
                    anderweitig &uuml;ber Produkte oder Informationen zu
                    informieren, die Sie interessieren k&ouml;nnten. Wir
                    k&ouml;nnen die Informationen, die wir &uuml;ber Sie
                    erfahren, auch dazu verwenden, um unsere Plattform auf
                    Websites Dritter zu bewerben.
                </li>
            </ul>
            <p className='p1'>
                <strong>Wie wir Ihre Informationen weitergeben</strong>
            </p>
            <ul className='ul1'>
                <li className='li1'>
                    Verbundene Unternehmen. Wir k&ouml;nnen die Informationen,
                    die wir von Ihnen erfassen, an unsere verbundenen
                    Unternehmen oder Tochtergesellschaften weitergeben, und zwar
                    ausschlie&szlig;lich zu dem Zweck, Ihnen die Plattform oder
                    damit verbundene Dienste, einschlie&szlig;lich
                    Untersite-Dienste, zur Verf&uuml;gung zu stellen; wenn wir
                    dies jedoch tun, wird die Nutzung und Weitergabe Ihrer
                    personenbezogenen Daten durch diese verbundenen Unternehmen
                    und Tochtergesellschaften in &Uuml;bereinstimmung mit dieser
                    Richtlinie erfolgen.
                </li>
                <li className='li1'>
                    Dienstanbieter. Wir k&ouml;nnen die von Ihnen gesammelten
                    Informationen an Drittanbieter, Dienstleister, Auftragnehmer
                    oder Vertreter weitergeben, die in unserem Auftrag
                    Funktionen ausf&uuml;hren, vorausgesetzt, diese Dritten
                    haben zugestimmt, diese Informationen nur zur Erbringung von
                    Dienstleistungen f&uuml;r uns zu verwenden.
                </li>
                <li className='li1'>
                    Gesch&auml;fts&uuml;bertragungen. Wenn wir in Verhandlungen
                    mit einem anderen Unternehmen oder einer anderen
                    K&ouml;rperschaft stehen, von einem anderen Unternehmen oder
                    einer anderen K&ouml;rperschaft &uuml;bernommen werden oder
                    mit einem anderen Unternehmen oder einer anderen
                    K&ouml;rperschaft fusionieren, wenn im Wesentlichen alle
                    unsere Verm&ouml;genswerte auf ein anderes Unternehmen oder
                    eine andere K&ouml;rperschaft &uuml;bertragen werden oder
                    als Teil eines Konkursverfahrens, k&ouml;nnen wir die von
                    Ihnen erfassten Daten an das andere Unternehmen oder die
                    andere K&ouml;rperschaft &uuml;bertragen.
                </li>
                <li className='li1'>
                    In Reaktion auf rechtliche Verfahren. Wir k&ouml;nnen die
                    von Ihnen erfassten Informationen auch offenlegen, um dem
                    Gesetz, einem Gerichtsverfahren, einem Gerichtsbeschluss
                    oder einem anderen rechtlichen Verfahren nachzukommen, z. B.
                    als Antwort auf eine Vorladung.
                </li>
                <li className='li1'>
                    Um uns und andere zu sch&uuml;tzen. Wir k&ouml;nnen die von
                    Ihnen erfassten Informationen auch offenlegen, wenn wir
                    glauben, dass dies notwendig ist, um illegale
                    Aktivit&auml;ten, mutma&szlig;lichen Betrug, Situationen,
                    die eine potenzielle Bedrohung f&uuml;r die Sicherheit von
                    Personen darstellen, Verst&ouml;&szlig;e gegen unsere
                    Dienstleistungsvereinbarung oder diese Richtlinie zu
                    untersuchen, zu verhindern oder Ma&szlig;nahmen zu
                    ergreifen, oder als Beweismittel in Rechtsstreitigkeiten, an
                    denen wir beteiligt sind.
                </li>
                <li className='li1'>
                    Zusammengefasste und de-identifizierte Informationen. Wir
                    k&ouml;nnen zusammengefasste oder nicht identifizierte
                    Informationen &uuml;ber Nutzer sammeln, verarbeiten,
                    analysieren und mit Dritten und der &Ouml;ffentlichkeit zu
                    Zwecken der Produktentwicklung, des Marketings, der Werbung,
                    der Forschung oder &auml;hnlichen Zwecken teilen.
                </li>
            </ul>
            <p className='p1'>
                <strong>
                    Recht auf Zugang, Berichtigung und L&ouml;schung
                </strong>
            </p>
            <p className='p1'>
                Sie k&ouml;nnen Ihr Konto oder die von uns erfassten
                pers&ouml;nlichen Daten jederzeit einsehen, bearbeiten,
                aktualisieren oder l&ouml;schen, indem Sie auf Ihre
                Kontoeinstellungen zugreifen oder uns eine E-Mail an{' '}
                <span className='s2'>safetravy@gmail.com </span>senden. Wir
                werden Ihre Anfragen innerhalb eines angemessenen Zeitraums
                beantworten, sp&auml;testens jedoch innerhalb des gesetzlich
                vorgeschriebenen Zeitraums.
            </p>
            <p className='p1'>
                Bitte beachten Sie, dass wir unter bestimmten Umst&auml;nden
                nicht in der Lage sind, einer Anfrage zur Bearbeitung,
                Aktualisierung, zum Zugriff oder zur L&ouml;schung eines
                Kontoprofils oder pers&ouml;nlicher Daten nachzukommen. Dies
                schlie&szlig;t ein, ist aber nicht beschr&auml;nkt auf:
            </p>
            <p className='p1'>
                -jede Grundlage, auf der ein solcher Antrag nach geltendem Recht
                abgelehnt werden kann;
            </p>
            <p className='p1'>
                -Wenn wir die Informationen aufbewahren m&uuml;ssen, um Bundes-,
                Landes- oder lokale Gesetze einzuhalten oder f&uuml;r
                Buchhaltungs- oder Steuerzwecke;
            </p>
            <p className='p1'>
                wenn wir einer zivil-, strafrechtlichen oder beh&ouml;rdlichen
                Anfrage, Untersuchung, Vorladung oder Vorladung durch Bundes-,
                Landes- oder Kommunalbeh&ouml;rden nachkommen m&uuml;ssen;
            </p>
            <p className='p1'>
                -wenn wir mit den Strafverfolgungsbeh&ouml;rden zusammenarbeiten
                m&uuml;ssen, weil das Unternehmen, der Dienstleister oder ein
                Dritter in gutem Glauben davon ausgeht, dass die Daten gegen
                Bundes-, Landes- oder lokale Gesetze versto&szlig;en;
            </p>
            <p className='p1'>
                -wenn wir Informationen aufbewahren m&uuml;ssen, um rechtliche
                Anspr&uuml;che geltend zu machen oder zu verteidigen;
            </p>
            <p className='p1'>
                -wenn die Informationen ein gesetzliches Privileg oder
                gesch&uuml;tzte Informationen einer anderen Partei enthalten;
                oder - wenn die Befolgung des Ersuchens die Privatsph&auml;re
                oder andere legitime Rechte anderer beeintr&auml;chtigen
                w&uuml;rde.
            </p>
            <p className='p1'>
                Wenn wir feststellen, dass wir eine Anfrage in einem bestimmten
                Fall nicht beantworten k&ouml;nnen, teilen wir Ihnen die
                Gr&uuml;nde f&uuml;r diese Entscheidung mit und nennen Ihnen
                eine Kontaktstelle f&uuml;r eventuelle R&uuml;ckfragen. Um Ihre
                Privatsph&auml;re zu sch&uuml;tzen, werden wir wirtschaftlich
                angemessene Schritte unternehmen, um Ihre Identit&auml;t zu
                &uuml;berpr&uuml;fen, bevor wir auf eine Anfrage
                gem&auml;&szlig; dieser Bestimmung antworten,
                einschlie&szlig;lich der Einhaltung aller anwendbaren
                gesetzlichen Anforderungen zur &Uuml;berpr&uuml;fung Ihrer
                Identit&auml;t.
            </p>
            <p className='p1'>
                <strong>&Uuml;bermittlung von Informationen</strong>
            </p>
            <p className='p1'>
                Bitte beachten Sie, dass wir Ihre personenbezogenen Daten auf
                Servern in den L&auml;ndern speichern k&ouml;nnen, in denen sich
                unsere Hauptniederlassungen befinden. Wenn diese Gerichtsbarkeit
                au&szlig;erhalb Ihres Wohnsitzes liegt, stimmen Sie der
                &Uuml;bertragung Ihrer personenbezogenen Daten in diese
                Gerichtsbarkeit zu, um Ihnen die Plattform zur Verf&uuml;gung
                stellen zu k&ouml;nnen, auch wenn diese andere Gerichtsbarkeit
                weniger Schutz f&uuml;r personenbezogene Daten bietet als Ihre
                Wohnsitzgerichtsbarkeit. Wir stellen sicher, dass
                Sicherheitsvorkehrungen getroffen werden, die mit unseren
                Verpflichtungen zur Wahrung der Sicherheit Ihrer
                personenbezogenen Daten gem&auml;&szlig; den Gesetzen Ihres
                Wohnsitzes &uuml;bereinstimmen.
            </p>
            <p className='p1'>
                <strong>Vorratsspeicherung</strong>
            </p>
            <p className='p1'>
                Wir bewahren Ihre personenbezogenen Daten so lange auf, wie Sie
                ein Konto auf der Plattform unterhalten. Wir h&ouml;ren auf,
                Ihre personenbezogenen Daten aufzubewahren, oder entfernen die
                Mittel, mit denen die personenbezogenen Daten mit bestimmten
                Personen in Verbindung gebracht werden k&ouml;nnen, sobald es
                vern&uuml;nftig ist, anzunehmen, dass -
            </p>
            <p className='p1'>
                (a) der Zweck, f&uuml;r den die personenbezogenen Daten erhoben
                wurden, durch die Aufbewahrung nicht mehr erf&uuml;llt wird; und
            </p>
            <p className='p1'>
                (b) die Aufbewahrung aus rechtlichen, buchhalterischen oder
                gesch&auml;ftlichen Gr&uuml;nden nicht mehr erforderlich ist.
            </p>
            <p className='p1'>
                Bitte beachten Sie, dass wir aufgrund bestimmter Gesetze
                verpflichtet sein k&ouml;nnen, Aufzeichnungen &uuml;ber
                Transaktionen oder Konten f&uuml;r einen bestimmten Zeitraum
                aufzubewahren.
            </p>
            <p className='p1'>
                <strong>Links von Drittanbietern</strong>
            </p>
            <p className='p1'>
                Unsere Plattform kann Links zu Websites Dritter enthalten. Der
                Zugriff auf und die Nutzung von solchen verlinkten Websites
                unterliegt nicht dieser Richtlinie, sondern den
                Datenschutzrichtlinien dieser Websites Dritter. Wir sind nicht
                verantwortlich f&uuml;r die Informationspraktiken solcher
                Websites Dritter.
            </p>
            <p className='p1'>
                <strong>Kinder unter 18 Jahren</strong>
            </p>
            <p className='p1'>
                Unsere Plattform ist nicht f&uuml;r Kinder unter 18 Jahren
                bestimmt. Wenn wir feststellen, dass ein Kind unter 18 Jahren
                uns personenbezogene Daten zur Verf&uuml;gung gestellt hat,
                werden wir diese Daten aus unseren Systemen l&ouml;schen.
            </p>
            <p className='p1'>
                <strong>&Auml;nderungen an unserer Datenschutzpolitik</strong>
            </p>
            <p className='p1'>
                Diese Richtlinie ist ab dem oben angegebenen Datum des
                Inkrafttretens g&uuml;ltig. Wir k&ouml;nnen diese Richtlinie von
                Zeit zu Zeit &auml;ndern, daher sollten Sie sie
                regelm&auml;&szlig;ig &uuml;berpr&uuml;fen. Wir werden alle
                &Auml;nderungen an dieser Richtlinie auf der Plattform
                ver&ouml;ffentlichen. Wenn wir &Auml;nderungen an dieser
                Richtlinie vornehmen, die sich wesentlich auf unsere Praktiken
                in Bezug auf die personenbezogenen Daten auswirken, die wir
                zuvor von Ihnen erfasst haben, werden wir uns bem&uuml;hen, Sie
                im Voraus &uuml;ber eine solche &Auml;nderung zu informieren,
                indem wir die &Auml;nderung auf unserer Plattform hervorheben
                oder eine Push-Benachrichtigung &uuml;ber die Website
                bereitstellen (Sie sollten sicherstellen, dass Ihre
                Website-Einstellungen solche Push-Benachrichtigungen zulassen)
                oder eine E-Mail senden, die Sie in Ihrem Konto angegeben haben
                (aus diesem Grund sollten Sie sicherstellen, dass Sie Ihre
                Kontoinformationen umgehend aktualisieren, wenn sie sich
                &auml;ndern).
            </p>
            <p className='p1'>
                <strong>Wie Sie uns kontaktieren können</strong>
            </p>
            <p className='p1'>
                Wenn Sie Fragen zur Datenschutzpolitik von SafeTravy haben, zu
                den Daten, die wir über Sie speichern, oder wenn Sie eines Ihrer
                Datenschutzrechte ausüben möchten, zögern Sie bitte nicht, uns
                zu kontaktieren: per E-Mail{' '}
                <span style={{ color: 'blue', textDecoration: 'underline' }}>
                    safetravy@gmail.com
                </span>{' '}
                oder per Telefon
                <a href='tel: +350 54095364'>+350 54095364</a>.
            </p>

            <p className='p2'>&nbsp;</p>
        </>
    ),
}

import { Languages } from '@/core/typing'

interface IFaQItem {
    id: string
    question: string
    answer: string
}

export const translateFAQEN: IFaQItem[] = [
    {
        id: '1',
        question: 'What is SafeTravy?',
        answer: 'SafeTravy is an all-in-one guidance solution for travelers. Our platform showcases informative content in the areas of climate, social, and medical situations around the world as well as visa and entry requirements. Besides informative content on world interactive map, SafeTravy offers personalized AI assistance for every type of travel and real-time push notifications covering emergency alerts on risks in the location of our customers.',
    },
    {
        id: '2',
        question: 'Is SafeTravy free to use?',
        answer: 'You can access our free trial period to discover functionality of our platform. However, free trial has limited accessibility and does not include some of its features. After 3 days of using it you can choose subscription plan that best fits your needs.',
    },
    {
        id: '3',
        question: 'How often is information updated on the platform?',
        answer: 'Information about countries is updated monthly, and push notifications about danger and weather conditions are provided in real time to ensure your safety.',
    },
    {
        id: '4',
        question: 'What do the different colors on the map mean?',
        answer: 'The colors on the map of the world reflect the level of risk. Red indicates a high risk, yellow indicates moderate risk, green means safe, and gray means there is no data available.',
    },
    {
        id: '5',
        question:
            'How can I find out visa requirements and allowed stay duration to the destination country?',
        answer: 'In the “Entry Requirements” section, select your departure and destination countries, and click “Search.” You will receive all necessary details, including visa requirements and permitted stay duration.',
    },
    {
        id: '6',
        question: 'How do I use the chatbot?',
        answer: 'You can interact with Travy, our AI assistant, who will help answer your questions related to traveling. For a better experience, take a quick survey when using Travy to personalize the responses and recommendations.',
    },
    {
        id: '7',
        question:
            'Can I receive alerts about any danger in the location I`m staying in?',
        answer: 'Yes, in your profile, you can subscribe to such notifications and get info in real time from SafeTravy.',
    },
]

export const translatesFAQDE: IFaQItem[] = [
    {
        id: '1',
        question: 'Was ist SafeTravy?',
        answer: 'SafeTravy ist eine All-in-One-Lösung für Reisende. Unsere Plattform bietet informative Inhalte in den Bereichen Klima, soziale und medizinische Situationen auf der ganzen Welt sowie Visa- und Einreisebestimmungen. Neben informativen Inhalten auf einer interaktiven Weltkarte bietet SafeTravy personalisierte KI-Hilfe für jede Art von Reise und Echtzeit-Push-Benachrichtigungen über Notfallwarnungen zu Risiken am Standort unserer Kunden.',
    },
    {
        id: '2',
        question: 'Ist die Nutzung von SafeTravy kostenlos?',
        answer: 'Sie können unsere kostenlose Testphase nutzen, um die Funktionalität unserer Plattform kennenzulernen. Die kostenlose Testphase ist jedoch nur eingeschränkt zugänglich und enthält einige ihrer Funktionen nicht. Nach 3 Tagen Nutzung können Sie das Abonnement wählen, das Ihren Anforderungen am besten entspricht.',
    },
    {
        id: '3',
        question:
            'Wie oft werden die Informationen auf der Plattform aktualisiert?',
        answer: 'Die Informationen über die Länder werden monatlich aktualisiert, und Push-Benachrichtigungen über Gefahren und Wetterbedingungen werden in Echtzeit bereitgestellt, um Ihre Sicherheit zu gewährleisten.',
    },
    {
        id: '4',
        question: 'Was bedeuten die verschiedenen Farben auf der Karte?',
        answer: 'Die Farben auf der Weltkarte spiegeln den Grad des Risikos wider. Rot steht für ein hohes Risiko, Gelb für ein mittleres Risiko, Grün für ein sicheres Risiko und Grau bedeutet, dass keine Daten verfügbar sind.',
    },
    {
        id: '5',
        question:
            'Wie kann ich mich über die Visabestimmungen und die zulässige Aufenthaltsdauer für das Zielland informieren?',
        answer: 'Wählen Sie im Abschnitt „Einreisebestimmungen“ Ihr Abreise- und Zielland aus und klicken Sie auf „Suchen“. Sie erhalten dann alle notwendigen Informationen, einschließlich der Visabestimmungen und der zulässigen Aufenthaltsdauer.',
    },
    {
        id: '6',
        question: 'Wie kann ich den Chatbot nutzen?',
        answer: 'Sie können mit Travy, unserem KI-Assistenten, interagieren, der Ihnen bei der Beantwortung Ihrer Fragen zum Thema Reisen hilft. Für ein besseres Erlebnis können Sie bei der Nutzung von Travy eine kurze Umfrage durchführen, um die Antworten und Empfehlungen zu personalisieren.',
    },
    {
        id: '7',
        question:
            'Kann ich Warnungen über Gefahren an dem Ort erhalten, an dem ich mich aufhalte?',
        answer: 'Ja, in Ihrem Profil können Sie sich für solche Benachrichtigungen anmelden und Informationen in Echtzeit von SafeTravy erhalten.',
    },
]

export const translatesFAQES: IFaQItem[] = [
    {
        id: '1',
        question: '¿Qué es SafeTravy?',
        answer: 'SafeTravy es una solución integral de orientación para viajeros. Nuestra plataforma muestra contenidos informativos sobre situaciones climáticas, sociales y médicas en todo el mundo, así como requisitos de visado y entrada. Además del contenido informativo en el mapa interactivo del mundo, SafeTravy ofrece asistencia personalizada de IA para cada tipo de viaje y notificaciones push en tiempo real que cubren alertas de emergencia sobre riesgos en la ubicación de nuestros clientes.',
    },
    {
        id: '2',
        question: '¿Es gratis utilizar SafeTravy?',
        answer: 'Puedes acceder a nuestro periodo de prueba gratuito para descubrir las funcionalidades de nuestra plataforma. Sin embargo, la prueba gratuita tiene accesibilidad limitada y no incluye algunas de sus funciones. Después de 3 días de uso, puedes elegir el plan de suscripción que mejor se adapte a tus necesidades.',
    },
    {
        id: '3',
        question:
            '¿Con qué frecuencia se actualiza la información en la plataforma?',
        answer: 'La información sobre los países se actualiza mensualmente, y las notificaciones push sobre peligros y condiciones meteorológicas se proporcionan en tiempo real para garantizar su seguridad.',
    },
    {
        id: '4',
        question: '¿Qué significan los diferentes colores del mapa?',
        answer: 'Los colores del mapa del mundo reflejan el nivel de riesgo. El rojo indica un riesgo alto, el amarillo un riesgo moderado, el verde significa seguro y el gris significa que no hay datos disponibles.',
    },
    {
        id: '5',
        question:
            '¿Cómo puedo averiguar los requisitos de visado y la duración permitida de la estancia en el país de destino?',
        answer: 'En la sección «Requisitos de entrada», seleccione sus países de salida y destino, y haga clic en «Buscar». Recibirá todos los detalles necesarios, incluidos los requisitos de visado y la duración permitida de la estancia.',
    },
    {
        id: '6',
        question: '¿Cómo se utiliza el chatbot?',
        answer: 'Puedes interactuar con Travy, nuestro asistente de IA, que te ayudará a responder tus preguntas relacionadas con viajes. Para una mejor experiencia, realice una encuesta rápida cuando utilice Travy para personalizar las respuestas y recomendaciones.',
    },
    {
        id: '7',
        question:
            '¿Puedo recibir alertas sobre cualquier peligro en el lugar en el que me alojo?',
        answer: 'Sí, en tu perfil puedes suscribirte a este tipo de notificaciones y recibir información en tiempo real de SafeTravy.',
    },
]

export const translatesFAQFR: IFaQItem[] = [
    {
        id: '1',
        question: "Qu'est-ce que SafeTravy?",
        answer: "SafeTravy est une solution d'orientation tout-en-un pour les voyageurs. Notre plateforme présente un contenu informatif sur les situations climatiques, sociales et médicales dans le monde, ainsi que sur les conditions de visa et d'entrée sur le territoire. En plus du contenu informatif sur la carte interactive du monde, SafeTravy offre une assistance IA personnalisée pour chaque type de voyage et des notifications push en temps réel couvrant les alertes d'urgence sur les risques dans le lieu où se trouvent nos clients.",
    },
    {
        id: '2',
        question: "L'utilisation de SafeTravy est-elle gratuite?",
        answer: "Vous pouvez accéder à notre période d'essai gratuite pour découvrir les fonctionnalités de notre plateforme. Cependant, l'essai gratuit a une accessibilité limitée et n'inclut pas certaines de ses fonctionnalités. Après 3 jours d'utilisation, vous pouvez choisir le plan d'abonnement qui correspond le mieux à vos besoins.",
    },
    {
        id: '3',
        question:
            'À quelle fréquence les informations sont-elles mises à jour sur la plateforme?',
        answer: 'Les informations sur les pays sont mises à jour mensuellement, et des notifications push sur les dangers et les conditions météorologiques sont fournies en temps réel pour assurer votre sécurité.',
    },
    {
        id: '4',
        question: 'Que signifient les différentes couleurs de la carte?',
        answer: "Les couleurs de la carte du monde reflètent le niveau de risque. Le rouge indique un risque élevé, le jaune un risque modéré, le vert une situation sûre et le gris l'absence de données.",
    },
    {
        id: '5',
        question:
            "Comment puis-je connaître les conditions d'obtention d'un visa et la durée de séjour autorisée dans le pays de destination?",
        answer: "Dans la section « Conditions d'entrée », sélectionnez votre pays de départ et votre pays de destination, puis cliquez sur « Rechercher ». Vous obtiendrez tous les détails nécessaires, y compris les exigences en matière de visa et la durée de séjour autorisée.",
    },
    {
        id: '6',
        question: 'Comment utiliser le chatbot?',
        answer: 'Vous pouvez interagir avec Travy, notre assistant IA, qui vous aidera à répondre à vos questions liées au voyage. Pour une meilleure expérience, répondez à un rapide sondage lorsque vous utilisez Travy afin de personnaliser les réponses et les recommandations.',
    },
    {
        id: '7',
        question:
            'Puis-je recevoir des alertes en cas de danger dans la région où je séjourne?',
        answer: 'Oui, dans votre profil, vous pouvez vous abonner à de telles notifications et recevoir des informations en temps réel de SafeTravy.',
    },
]

export const transaltesFAQSConfig = {
    [Languages.EN]: translateFAQEN,
    [Languages.DE]: translatesFAQDE,
    [Languages.ES]: translatesFAQES,
    [Languages.FR]: translatesFAQFR,
}

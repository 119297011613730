import fullLogo from '@/assets/images/full_logo.png'
import styles from './styles.module.css'
import { GoBack, Icon, LogoutModal, PrimaryButton } from '@/core/components'
import { useNavigate } from 'react-router-dom'
import { createStyleSheet } from '@/core/helpers'
import { useAccount } from '../../hooks'
import _ from 'lodash'
import { useState } from 'react'
import { appEvents } from '@/core/events'
import { useCategoryRiskStore } from '@/store'
import { CategoryRisks } from '@/core/typing'
import { useTranslation } from 'react-i18next'
import { ChooseLanguageWidget } from '@/core/widgets/chose-language'
import { useResizeWindow } from '@/core/hooks'

export const AccountHeader = () => {
    const { t } = useTranslation()
    const { width: windowWidth } = useResizeWindow()
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const { setGroupCategory } = useCategoryRiskStore()
    const close = () => setOpenModal(false)

    const openModalLogout = () => setOpenModal(true)

    const { logOut } = useAccount()
    const changePassword = () =>
        navigate('/change/password', { state: { isChange: true } })

    const goMainPage = () => {
        goBack()
        setGroupCategory({
            category: CategoryRisks.Owerview,
            subcategory: null,
        })
    }

    const goBack = () => appEvents.emit('account', { isShow: false })

    return (
        <div className={styles.container} id='account-header'>
            <div className={styles.left_container} id='back-button'>
                <GoBack onClick={() => goBack()} />

                <img
                    src={fullLogo}
                    className={styles.logo}
                    onClick={goMainPage}
                />
                <p className={styles.title}>{t('accountOverview')}</p>
            </div>

            <div className={styles.right_container}>
                <ChooseLanguageWidget hideFlag={windowWidth < 768} />

                <div className={styles.change_password}>
                    <PrimaryButton
                        label={t('changePassword')}
                        onClick={changePassword}
                        className={styles.change_password_btn}
                        labelStyle={inlineStyles.label_btn}
                    />
                    <div
                        className={styles.logout}
                        onClick={openModalLogout}
                        data-testid='logout-button'>
                        <Icon
                            onClick={_.noop}
                            name='logout'
                            size={20}
                            color='#262933'
                            style={{ transform: 'scaleX(-1)' }}
                        />
                    </div>
                </div>
            </div>
            <LogoutModal isOpen={openModal} close={close} confirm={logOut} />
        </div>
    )
}

const inlineStyles = createStyleSheet({
    label_btn: {
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 20,
        color: '#262933',
    },
})

import { Languages } from '@/core/typing'
import styles from './styles.module.css'

export const translatesAboutUs: any = {
    [Languages.EN]: (
        <>
            <p className={styles.main_info}>
                Meet your pocket travel buddy. SafeTravy provides the info you
                may need to make each trip smooth, well-planned, and enjoyable.
                Our world maps, AI assistant, and highly personalized push
                notifications will keep you updated on conflicts, social
                conditions, medical situations, climate, and visa requirements
                worldwide.
            </p>

            <p color='#414244' style={{ marginBottom: 16 }}>
                Our mission remains simple: we believe all travelers across the
                globe deserve to have seamless journeys and fun on their trips.
                SafeTravy’s services help avoid multiple problems along the way.
            </p>

            <p color='#414244' style={{ marginBottom: 16, fontWeight: '500' }}>
                How SafeTravy appeared to help make each trip unforgettable
            </p>

            <p style={{ fontWeight: '500' }}>Inspiration</p>
            <p style={{ marginBottom: 16 }} color='#414244'>
                SafeTravy was born from a traveler's frustration with managing
                trip complexities like visas and local conditions. We aimed to
                create a centralized travel information hub to provide all the
                essentials you need to know when planning a journey.
            </p>

            <p style={{ fontWeight: '500' }}>Taking Flight</p>
            <p color='#414244' style={{ marginBottom: 16 }}>
                We launched with an interactive world map, AI assistant, and
                personalized push notifications, giving particular information
                to female travelers, LGBTQ+ society, and all travelers. We offer
                easy access to crucial information from climate to conflicts.
            </p>
        </>
    ),
    [Languages.DE]: (
        <>
            <p className={styles.main_info}>
                Lernen Sie Ihren Reisebegleiter für die Hosentasche kennen.
                SafeTravy bietet die Informationen, die Sie benötigen, um jede
                Reise reibungslos, gut geplant und angenehm zu gestalten. Unsere
                Weltkarten, unser KI-Assistent und unsere hochgradig
                personalisierten Push-Benachrichtigungen halten Sie über
                Konflikte, soziale Bedingungen, medizinische Situationen, Klima
                und Visabestimmungen weltweit auf dem Laufenden.
            </p>

            <p color='#414244' style={{ marginBottom: 16 }}>
                Unsere Mission ist einfach: Wir glauben, dass alle Reisenden auf
                der ganzen Welt es verdienen, nahtlos zu reisen und Spaß auf
                ihren Reisen zu haben. Die Dienste von SafeTravy helfen,
                zahlreiche Probleme auf dem Weg zu vermeiden.
            </p>

            <p color='#414244' style={{ marginBottom: 16, fontWeight: '500' }}>
                Wie SafeTravy erschien, um jede Reise unvergesslich zu machen
            </p>

            <p style={{ fontWeight: '500' }}>Inspiration</p>

            <p style={{ marginBottom: 16 }} color='#414244'>
                SafeTravy entstand aus der Frustration eines Reisenden, der sich
                mit der Verwaltung komplexer Reiseangelegenheiten wie Visa und
                lokalen Bedingungen herumschlagen musste. Unser Ziel war es,
                eine zentrale Anlaufstelle für Reiseinformationen zu schaffen,
                die alle wichtigen Informationen zur Verfügung stellt, die man
                bei der Planung einer Reise benötigt.
            </p>

            <p style={{ fontWeight: '500' }}>Abheben</p>

            <p color='#414244' style={{ marginBottom: 16 }}>
                Wir sind mit einer interaktiven Weltkarte, einem KI-Assistenten
                und personalisierten Push-Benachrichtigungen an den Start
                gegangen und haben insbesondere Informationen für weibliche
                Reisende, die LGBTQ+-Gesellschaft und alle Reisenden
                bereitgestellt. Wir bieten einfachen Zugang zu wichtigen
                Informationen, vom Klima bis zu Konflikten.
            </p>
        </>
    ),
    [Languages.ES]: (
        <>
            <p className={styles.main_info}>
                Conozca a su compañero de viaje de bolsillo. SafeTravy
                proporciona la información que pueda necesitar para hacer cada
                viaje sin problemas, bien planificado y agradable. Nuestros
                mapas del mundo, el asistente de inteligencia artificial y las
                notificaciones push altamente personalizadas te mantendrán al
                día sobre conflictos, condiciones sociales, situaciones médicas,
                clima y requisitos de visado en todo el mundo.
            </p>

            <p color='#414244' style={{ marginBottom: 16 }}>
                Nuestra misión sigue siendo sencilla: creemos que todos los
                viajeros del mundo merecen tener viajes sin contratiempos y
                divertirse en sus desplazamientos. Los servicios de SafeTravy
                ayudan a evitar múltiples problemas en el camino.
            </p>

            <p color='#414244' style={{ marginBottom: 16, fontWeight: '500' }}>
                Cómo apareció SafeTravy para ayudar a que cada viaje sea
                inolvidable
            </p>

            <p style={{ fontWeight: '500' }}>Inspiración</p>

            <p style={{ marginBottom: 16 }} color='#414244'>
                SafeTravy nació de la frustración de un viajero ante la gestión
                de complejidades del viaje como los visados y las condiciones
                locales. Nuestro objetivo era crear un eje centralizado de
                información sobre viajes que proporcionara todo lo esencial que
                hay que saber a la hora de planificar un viaje.
            </p>

            <p style={{ fontWeight: '500' }}>Alzar el vuelo</p>

            <p color='#414244' style={{ marginBottom: 16 }}>
                Nos lanzamos con un mapamundi interactivo, un asistente de IA y
                notificaciones push personalizadas, ofreciendo información
                particular a las mujeres viajeras, a la sociedad LGBTQ+ y a
                todos los viajeros. Ofrecemos fácil acceso a información
                crucial, desde el clima hasta los conflictos.
            </p>
        </>
    ),
    [Languages.FR]: (
        <>
            <p className={styles.main_info}>
                Voici votre compagnon de voyage de poche. SafeTravy fournit les
                informations dont vous pouvez avoir besoin pour rendre chaque
                voyage fluide, bien planifié et agréable. Nos cartes du monde,
                notre assistant IA et nos notifications push hautement
                personnalisées vous tiendront au courant des conflits, des
                conditions sociales, des situations médicales, du climat et des
                exigences en matière de visa dans le monde entier.
            </p>

            <p color='#414244' style={{ marginBottom: 16 }}>
                Notre mission reste simple : nous pensons que tous les voyageurs
                du monde entier méritent de voyager sans encombre et de
                s'amuser. Les services de SafeTravy permettent d'éviter de
                nombreux problèmes en cours de route.
            </p>

            <p color='#414244' style={{ marginBottom: 16, fontWeight: '500' }}>
                Comment SafeTravy est apparu pour aider à rendre chaque voyage
                inoubliable
            </p>

            <p style={{ fontWeight: '500' }}>L'inspiration</p>

            <p style={{ marginBottom: 16 }} color='#414244'>
                SafeTravy est né de la frustration d'un voyageur face à la
                complexité de la gestion des visas et des conditions locales.
                Notre objectif était de créer un centre d'information centralisé
                sur les voyages afin de fournir tous les éléments essentiels à
                connaître lors de la préparation d'un voyage.
            </p>

            <p style={{ fontWeight: '500' }}>L'envol</p>

            <p color='#414244' style={{ marginBottom: 16 }}>
                Nous avons lancé une carte du monde interactive, un assistant IA
                et des notifications push personnalisées, en donnant des
                informations particulières aux voyageuses, à la société LGBTQ+
                et à tous les voyageurs. Nous offrons un accès facile à des
                informations cruciales, du climat aux conflits.
            </p>
        </>
    ),
}

import { SubscriptionType } from '../typing/enums'
import basic from '@/assets/images/basic.svg'
import extra from '@/assets/images/extra.svg'
import lightCheck from '@/assets/images/check.svg'

import transprentCheck from '@/assets/images/check_transparent.svg'
import { TariffTheme } from '../typing/interface'

export const premium3Month = [
    // 'conflictsWorldwide',
    // 'socialIssues',
    // 'healthLabel',
    // 'climateLabel',
    // 'monthlyDataUpdates',
    // 'emergencyPush',
    'tailoredAI',
    'sightsAI',
    'hotelTicketsAI',
    'personalizedVisa',
    // 'womenLgbt',
]

export const basic3Month = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'visaEntry',
    'emergencyPush',
    'womenLgbt',
]

export const premium = [
    // 'conflictsWorldwide',
    // 'socialIssues',
    // 'healthLabel',
    // 'climateLabel',
    // 'monthlyDataUpdates',
    // 'emergencyPush',
    'tailoredAI',
    'sightsAI',
    'hotelTicketsAI',
    'personalizedVisa',
    // 'womenLgbt',
]

export const base = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'visaEntry',
    'emergencyPush',
    'womenLgbt',
]

export const subscribeLabel = {
    [SubscriptionType.Basic]: 'Basic',
    [SubscriptionType.Premium]: 'Extra',
    [SubscriptionType.Basic_3m]: 'Basic 3 month',
    [SubscriptionType.Premium_3m]: 'Extra 3 month',
}

export const subscribeRedesignLabel = {
    [SubscriptionType.Basic]: 'discoverBasic',
    [SubscriptionType.Premium]: 'discoverExtra',
    [SubscriptionType.Basic_3m]: 'discoverBasic',
    [SubscriptionType.Premium_3m]: 'discoverExtra',
}

export const imageTariffConfig: any = {
    [SubscriptionType.Basic]: basic,
    [SubscriptionType.Premium]: extra,
    [SubscriptionType.Basic_3m]: basic,
    [SubscriptionType.Premium_3m]: extra,
}

export const themeTariff: Record<SubscriptionType, TariffTheme> = {
    [SubscriptionType.Basic]: {
        background: '#FFFFFF',
        txtColor: '#262933',
        secondaryTxt: '#5E626D',
        primaryBtn: '#0D8F5B',
        primaryBtnLabelColor: '#FFFFFF',
        secondaryBtn: '#F5F5FA',
        secondaryBtnLabelColor: '#262933',
        icon: basic,
        iconCheck: lightCheck,
        vat: '#AFB1B6',
    },
    [SubscriptionType.Premium]: {
        background: '#0D8F5B',
        txtColor: '#FFFFFF',
        secondaryTxt: '#F8F8FA',
        primaryBtn: '#FFFFFF',
        primaryBtnLabelColor: '#0D8F5B',
        secondaryBtn: '#FFFFFF14',
        secondaryBtnLabelColor: '#FFFFFF',
        icon: extra,
        iconCheck: transprentCheck,
        vat: '#ABEFCA',
    },
    [SubscriptionType.Basic_3m]: {
        background: '#FFFFFF',
        txtColor: '#262933',
        secondaryTxt: '#5E626D',
        primaryBtn: '#0D8F5B',
        primaryBtnLabelColor: '#FFFFFF',
        secondaryBtn: '#F5F5FA',
        secondaryBtnLabelColor: '#262933',
        icon: basic,
        iconCheck: lightCheck,
        vat: '#AFB1B6',
    },
    [SubscriptionType.Premium_3m]: {
        background: '#0D8F5B',
        txtColor: '#FFFFFF',
        secondaryTxt: '#F8F8FA',
        primaryBtn: '#FFFFFF',
        primaryBtnLabelColor: '#0D8F5B',
        secondaryBtn: '#FFFFFF14',
        secondaryBtnLabelColor: '#FFFFFF',
        icon: extra,
        iconCheck: transprentCheck,
        vat: '#ABEFCA',
    },
}

import { PrimaryButton } from '@/core/components'
import styles from '../styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    onCklick: () => void
    type: 'full' | 'outlined'
    price: string
    trialPeriod: string
    label: string
    hibeBtn?: boolean
}

export const PriceAtom = ({
    onCklick,
    price,
    trialPeriod,
    label,
    hibeBtn,
}: IProps) => {
    const { t } = useTranslation()
    const isActiveFooter = () => {
        if (!hibeBtn) return null
        return (
            <div className={styles.active_footer}>
                <p className={styles.active_label}>{t('currentTariff')}</p>
            </div>
        )
    }
    return (
        <div className={styles.bottom_container}>
            <p className={styles.description}>
                {'\u2022'} {trialPeriod}
            </p>

            <div className={styles.footer_price}>
                <div className={styles.price_row}>
                    <p className={styles.price}>€{price}</p>
                    <p className={styles.interval_label}>
                        <p className={styles.vat}>+{t('vat')}</p>/{t('month')}
                    </p>
                </div>
                {hibeBtn ? null : (
                    <PrimaryButton
                        className={styles.started_btn}
                        label={label}
                        onClick={onCklick}
                        labelStyle={{ fontSize: 14, fontWeight: '600' }}
                    />
                )}
            </div>
            {isActiveFooter()}
        </div>
    )
}

import { Button } from 'antd'
import styles from './styles.module.css'
import fullLogo from '@/assets/images/full_logo.png'
import { Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { appEvents } from '@/core/events'
import { useEventsListener } from '@/core/hooks'
import { AuthStep } from '@/modules/auth/typing'
import { SwitchTarrifs, Tariff } from '../../components'
import {
    base,
    basic3Month,
    premium,
    premium3Month,
} from '../../configs/advantes.config'

export const TariffPlansPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [type, setType] = useState<'q' | 'm'>('q')
    const [modal, setModal] = useState<{
        isShow: boolean
    }>({
        isShow: false,
    })

    const close = () => {
        appEvents.emit('previewTariffs', {
            isShow: false,
        })
        setType('q')
    }

    useEventsListener(
        'previewTariffs',
        (data: any) => {
            setModal({
                isShow: data?.isShow,
            })
        },
        [],
    )

    const goRegister = () =>
        navigate('/auth', {
            state: { step: AuthStep.Register },
        })

    const goBackButton = () => {
        return (
            <Button className={styles.go_back} type='text' onClick={close}>
                <Icon name='left-arrow' size={20} color='#000000' />{' '}
                <p>{t('back')}</p>
            </Button>
        )
    }

    if (!modal.isShow) return null

    return (
        <div className={styles.container}>
            <div className={styles.img_illustration}></div>
            <div className={styles.right_container}>
                <div style={{ width: '100%' }}>
                    <div className={styles.header}>
                        <>{goBackButton()}</>
                        <i
                            onClick={close}
                            className={`icon-left-arrow ${styles.left_arrow}`}
                        />
                        <img src={fullLogo} className={styles.fullLogo} />
                    </div>
                    <p className={styles.title}>
                        Explore the world with SafeTravy
                    </p>
                </div>
                <SwitchTarrifs value={type} onChange={setType} />
                <div className={styles.content}>
                    {type === 'q' ? (
                        <>
                            <Tariff
                                isTrial={false}
                                isActive={false}
                                labelBtn={t('getStarted')}
                                trialPeriod={t('freeTrial')}
                                advantes={basic3Month}
                                label={t('basic3')}
                                type={t('popular')}
                                isPrimary={false}
                                price='7,99'
                                onClick={goRegister}
                            />
                            <Tariff
                                isTrial={false}
                                isActive={false}
                                labelBtn={t('getStarted')}
                                trialPeriod={t('freeTrial')}
                                includeBasic
                                advantes={premium3Month}
                                isHaveHat={true}
                                label={t('extra3')}
                                type={t('popular')}
                                isPrimary={true}
                                price='9,99'
                                onClick={goRegister}
                            />
                        </>
                    ) : (
                        <>
                            <Tariff
                                isTrial={false}
                                isActive={false}
                                labelBtn={t('getStarted')}
                                trialPeriod={t('freeTrial')}
                                advantes={base}
                                label={t('basic')}
                                isPrimary={false}
                                price='8,99'
                                onClick={goRegister}
                            />
                            <Tariff
                                isTrial={false}
                                isActive={false}
                                labelBtn={t('getStarted')}
                                trialPeriod={t('freeTrial')}
                                includeBasic
                                advantes={premium}
                                label={t('extra')}
                                isPrimary={true}
                                price='10,99'
                                onClick={goRegister}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

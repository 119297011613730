import axios from 'axios'
import { IAstroWeatherParams, IWeatherFullForecastResponse } from './interfaces'

export const getCurrentWeatherReq = (capitalName: string) => {
    return axios.get(WEATHER_BASE_URL + '/marine.json', {
        params: { key: apiKey, q: capitalName, alerts: 'yes' },
    })
}

export const WEATHER_BASE_URL = 'https://api.weatherapi.com/v1'
const apiKey = import.meta.env.VITE_WEATHER_API_KEY

export const getGroupForecastReq = (coordinates: string, lang: string) => {
    return axios.get<IWeatherFullForecastResponse>(
        WEATHER_BASE_URL + '/forecast.json',
        {
            params: {
                key: apiKey,
                q: coordinates,
                days: 9,
                alerts: 'yes',
                lang,
            },
        },
    )
}

export const getAstroWeatherReq = (params: IAstroWeatherParams) => {
    return axios.get<any>(WEATHER_BASE_URL + '/astronomy.json', {
        params: { ...params, key: apiKey },
    })
}

import { Check } from '@/core/components'
import styles from './styles.module.css'
import { TypeCategoryNotifications } from '@/modules/notifications/typing'
import { LayoutSettings } from '@/modules/notifications/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    types: TypeCategoryNotifications[]
    onChange: (val: TypeCategoryNotifications) => void
    disabled?: boolean
    titleClassName?: string
}
export const CategoryNotifications = ({
    types,
    onChange,
    disabled,
    titleClassName,
}: IProps) => {
    const { t } = useTranslation()
    const isChecked = (key: TypeCategoryNotifications) => types.includes(key)
    return (
        <LayoutSettings
            titleClassName={titleClassName}
            title={t('chooseNotifications')}>
            <div className={styles.check_box_list}>
                <Check
                    disabled={disabled}
                    label={t('geoConflicts')}
                    value={TypeCategoryNotifications.GeopoliticalConflicts}
                    checked={isChecked(
                        TypeCategoryNotifications.GeopoliticalConflicts,
                    )}
                    onChange={val => onChange(val.target.value)}
                />

                <Check
                    disabled={disabled}
                    label={t('internalConflicts')}
                    value={TypeCategoryNotifications.InternalSocialTensions}
                    checked={isChecked(
                        TypeCategoryNotifications.InternalSocialTensions,
                    )}
                    onChange={val => onChange(val.target.value)}
                />
                <Check
                    disabled={disabled}
                    label={t('potentialInsecurity')}
                    value={TypeCategoryNotifications.PotentialInsecurity}
                    checked={isChecked(
                        TypeCategoryNotifications.PotentialInsecurity,
                    )}
                    onChange={val => onChange(val.target.value)}
                />

                <Check
                    disabled={disabled}
                    label={t('climate')}
                    value={TypeCategoryNotifications.ClimateEnvironmental}
                    checked={isChecked(
                        TypeCategoryNotifications.ClimateEnvironmental,
                    )}
                    onChange={val => onChange(val.target.value)}
                />

                <Check
                    disabled={disabled}
                    label={t('medical')}
                    value={TypeCategoryNotifications.MedicalRisk}
                    checked={isChecked(TypeCategoryNotifications.MedicalRisk)}
                    onChange={val => onChange(val.target.value)}
                />

                <Check
                    disabled={disabled}
                    label={t('women')}
                    value={TypeCategoryNotifications.FemaleTravel}
                    checked={isChecked(TypeCategoryNotifications.FemaleTravel)}
                    onChange={val => onChange(val.target.value)}
                />

                <Check
                    disabled={disabled}
                    label={t('LGBTQ')}
                    value={TypeCategoryNotifications.LGBT}
                    checked={isChecked(TypeCategoryNotifications.LGBT)}
                    onChange={val => onChange(val.target.value)}
                />

                <Check
                    disabled={disabled}
                    label={t('bordersEntry')}
                    value={TypeCategoryNotifications.BordersEntry}
                    checked={isChecked(TypeCategoryNotifications.BordersEntry)}
                    onChange={val => onChange(val.target.value)}
                />

                <Check
                    disabled={disabled}
                    label={t('transportUpdates')}
                    value={TypeCategoryNotifications.TransportUpdates}
                    checked={isChecked(
                        TypeCategoryNotifications.TransportUpdates,
                    )}
                    onChange={val => onChange(val.target.value)}
                />
            </div>
        </LayoutSettings>
    )
}

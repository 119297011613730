import { Icon } from '@/core/components'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { ContactUs } from '@/modules/root/components'
import { Drawer } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'
import { translatesAboutUs } from './translates-config'
import { useLanguageStore } from '@/store'

export const AboutUsWidget = () => {
    const { lang } = useLanguageStore()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { width: widthWindow } = useResizeWindow()

    useEventsListener(
        'aboutUs',
        data => {
            setOpen(data?.isShow)
        },
        [],
    )

    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 1024:
                return '100dvw'

            default:
                return '736px'
        }
    }

    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            classNames={{ body: styles.body }}
            styles={{
                body: {
                    overflow: 'hidden',
                    padding: 0,
                },
                content: {
                    borderRadius: widthWindow < 768 ? 0 : 24,
                    backdropFilter: 'blur(24px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
                wrapper: {
                    width: drawerWidth(),
                    borderRadius: 24,
                    padding: widthWindow > 767 ? '8.5px 8px' : 0,
                    boxShadow: 'none',
                },
            }}
            onClose={() => setOpen(false)}>
            <div className={styles.header}>
                <p className={styles.title}>{t('aboutUs')}</p>

                <Icon
                    name='x'
                    size={22}
                    color='#5E626D'
                    onClick={() => setOpen(false)}
                />
            </div>
            <div className={styles.content}>
                <div>{translatesAboutUs[lang]}</div>
                <ContactUs />
            </div>
        </Drawer>
    )
}

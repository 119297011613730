import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { MainPage } from './pages/main'
import { useEffect } from 'react'
import { ManagePasswordPage, TermsConditionsPage } from '../auth/pages'
import { useAccount } from '../user/hooks'
import { useCountUnreadNotificationsStore } from '@/store'
import { getAllUnreadNotificationsReq } from '../notifications/api'
import { realTimeService } from '@/core/services'
import { Page404 } from './pages/404'
import { useOnesignal, useSocketListener } from '@/core/hooks'
import * as Sentry from '@sentry/react'
import { PrivacyPolicyPage } from '../auth/pages/privacy-policy'
import { DeprecatedDetailedWeather } from '../weather/pages/deprecated-detailed-weather'

export const PrivateModule = () => {
    const { initOnesignal } = useOnesignal()
    const { getAccount, account, storageClearSession } = useAccount()
    const { setCount, count } = useCountUnreadNotificationsStore()

    const getUnreadCountNotifications = async () => {
        try {
            const { data } = await getAllUnreadNotificationsReq()
            setCount(data)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    useSocketListener(
        'notification/new-notification',
        () => {
            getUnreadCountNotifications()
        },
        [count],
    )

    useSocketListener(
        'stopSessions',
        () => {
            storageClearSession()
        },
        [],
    )

    useEffect(() => {
        getAccount()
        realTimeService.init()
        getUnreadCountNotifications()
    }, [])

    useEffect(() => {
        if (account) {
            initOnesignal()
        }
    }, [account])

    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<MainPage />} />
                <Route path='/terms' element={<TermsConditionsPage />} />
                <Route path='/policy' element={<PrivacyPolicyPage />} />
                <Route
                    path='/weather'
                    element={<DeprecatedDetailedWeather />}
                />
                <Route
                    path='/change/password'
                    element={<ManagePasswordPage />}
                />
                <Route path='/404' element={<Page404 />} />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
        </BrowserRouter>
    )
}

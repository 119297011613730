import { GoBack } from '@/core/components'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import fullLogo from '@/assets/images/full_logo.png'
import { AccountGroupWidget } from '@/core/widgets'

interface IWeatherLocation {
    city: string
    country: string
    region: string
}
interface IProps {
    weatherLocation: IWeatherLocation
    clearSearchCity: () => void
}
export const DeprecatedHeaderWeather = ({
    weatherLocation,
    clearSearchCity,
}: IProps) => {
    const navigate = useNavigate()

    const handleGoBack = () => {
        clearSearchCity()
        navigate(-1)
    }
    return (
        <div className={styles.container}>
            <div className={styles.left_container}>
                <GoBack onClick={handleGoBack} />

                <img src={fullLogo} className={styles.logo} />

                <p className={styles.label}>
                    {weatherLocation.city}{' '}
                    <span>{`(${weatherLocation.region} ${weatherLocation.country})`}</span>
                </p>
            </div>

            <div className={styles.right_container}>
                <AccountGroupWidget />
            </div>
        </div>
    )
}
